export default {
    welcomeBack: 'ברוך שובך!',
    myTasks: 'המשימות שלי',
    helloName: 'שלום {name}!',
    thanksForFamily: 'תודה שהצטרפת לתמוך במשפחת {family_name}.',
    alwaysReady: 'תמיד מוכן לעזור!',
    progressBar: '{part} מתוך {total} משימות שנתפסו',
    allTasks: 'כל המשימות',
    datelessTasks: 'משימות ללא תאריך',
    iWillTakeIt: "אני על זה!",
    with_date: 'עם תאריך',
    dateless: 'ללא תאריך',
    thanksForCaring: 'מילים לא יכולות לבטא את ההוקרה שלנו. המעשים שלך מדברים בעד עצמם. תודה!',
    percent38: '',
    setSms: 'שלח לי תזכורת ב-SMS',
    addCalendar: 'הוסף ליומן',
    cancel: 'ביטול',
    submit: 'שלח',
    monday: 'ב׳',
    tuesday: 'ג׳',
    wednesday: 'ד׳',
    thursday: 'ה׳',
    friday: 'ו׳',
    saturday: 'ש׳',
    sunday: 'א׳',


    january: "ינואר",
    february: "פברואר",
    march: "מרץ",
    april: "אפריל",
    may: "מאי",
    june: "יוני",
    july: "יולי",
    august: "אוגוסט",
    september: "ספטמבר",
    october: "אוקטובר",
    november: "נובמבר",
    december: "דצמבר",

    translate: "תרגם",
    refuseYesNo: "האם אתה בטוח שאתה רוצה לסרב?",
    refuseIfYes: "אם כן, אנא תאר למה בתיבה למטה.",
    yourAnswer: "תשובתך",
    back: "חזור",
    confirm: "אשר",
    areYouSure: "האם אתה בטוח?",
    didYouComplete: "האם באמת השלמת את המשימה?",
    yes: "כן",
    no: "לא",

    noTaskThis: "אין משימות בתאריך שנבחר זמינות. גם אין משימות ללא תאריך",
    noTaskTaken: "לא נלקחו משימות. אנא עבור לדף המשימות הראשי וקח כמה משימות",
    noTaskAvailable: "אין משימות זמינות. אנא בקש מבעל המשפחה ליצור משימות למשפחה",

    errLoginTitle: "אנא התחבר",
    errLoginDescription: "דף זה דורש אימות. אנא התחבר דרך SMS",
    errAuthTitle: "אימות שגוי",
    errAuthDescription: "דף זה דורש אימות אך האסימונים השמורים אינם תקפים. נסה להתחבר שוב דרך SMS",
    errLoadTitle: "שגיאה בדף",
    errLoadDescription: "משהו בלתי צפוי קרה. אנא צור קשר עם התמיכה",
    errNetworkTitle: "לא זמין",
    errNetworkDescription: "אנא בדוק את חיבור האינטרנט שלך וטען מחדש את הדף",
    errServerTitle: "משהו לא תקין בשרת",
    errServerDescription: "אנא נסה לטעון מאוחר יותר",

    decline: "לדחות",
    done: "בוצע",
    onlyDatelessStr: "אין משימה ליום הנוכחי, אבל אתה יכול לעשות זאת היום:",
    datelessStr: "אתה יכול גם לעשות זאת היום:",
    viewDetails: "צפה בפרטים",
    taken: "נלקח",
    noDescriprion: "אין תיאור",
    tryAgain: "נסה שוב",
    happyJourney: "שמח שהצטרפת למסע שלנו",
    notForMe: "לא בשבילי",
    mytasks: "המשימות שלי",
    signUp: "הרשמה",
    wNumber: "מהו מספר הטלפון שלך?",
    wName: " מה שמך?",
    greatTo: "נהדר שאתה כאן",
    anyHelp: "כל עזרה שתספק תעשה הבדל",

    scrollToAgree: 'גלילה לאישור',
    ok_scroll: 'אישור והמשך',

    howCanI: 'איך אני יכול לעזור',
    anyTaskTake: 'אפילו אם תקחו דבר קטן ,זה יעזור מאד ',
    noTaskYet: 'בינתיים אין משימות',

    welcomeBackName: 'ברוך שובך {first_name}!',
    helpingToFamily: 'תמיכה ב ',

    goBack: 'תחזור',

    completed_: "הושלם",
    unassigned_: "לא הוקצה",
    taken_: "נלקח",
    
    notiTaskCompleted: 'המשימה "{taskName}" הושלמה',    
    notiTaskUnassigned: 'המשימה "{taskName}" בוטלה',
    notiTaskTaken: 'המשימה "{taskName}" נלקחה',

    set_: 'כדי להגדיר',
    pleasePickTime: 'בחר זמן',
    pickDate: 'נא להזין תאריך',
    pickTime: 'ציין זמן',
    itsTimeFor: "הגיע הזמן ללוח השנה של אפל/גוגל",

    signingIn_1: 'הכניסה מהווה אישור שקראת את ',
    termsOfUse: 'תנאי השימוש',
    signingIn_2: ' ',
    privacyPolicy:'ומדיניות הפרטיות',
    signingIn_3: " של אנולה, שנועדה לשמור על המשפחה ולייעל את התמיכה עבורה ",

    thisIsYourTask: 'זו המשימה שלך',

    switchAccounts: 'החלפת חשבונות',
    switch: 'החלף',
    logout: 'להתנתק',
    current: 'נוֹכְחִי',
    help: 'עֶזרָה',

    monday_: 'ב',
    tuesday_: 'ג',
    wednesday_: 'ד',
    thursday_: 'ה',
    friday_: ' ו',
    saturday_: ' ש',
    sunday_: 'א',
    family: 'מִשׁפָּחָה',

    signUpRequestError: 'שגיאת בקשת הרשמה',
    pleaseEnterValidPhone: 'נא להזין מספר טלפון קיים חוקי',
    youAreRegistered: 'מכיוון שאת/ה כבר חלק ממעגל התמיכה של המשפחה, קישור ייעודי נשלח אליך במסרון',
} as const;
