type Indication = {
    no_task_date: Array<number>;
    days_with_tasks: Array<number>;
    tasks_1_2: Array<number>;
    tasks_3_5: Array<number>;
    tasks_6_9: Array<number>;
    tasks_10_and_more: Array<number>;
};
function generateMonthIndication(month: number, year: number, events: Array<any>): Indication {
    console.log("indication month:"+month+",year:"+year)
    var array31: Array<number> = [...Array(32).fill(0)];
    events.forEach((event) => {
        if (event.status != "completed") {
            var dateStart:Date = new Date(event.date + "T" + event.start_at);
            var y: number = dateStart.getFullYear();
            var m: number = dateStart.getMonth();
            if (y == year && m == month) {
                console.log("indication event", event);
                console.log("indication task date", dateStart);
                var day: number = dateStart.getDate();
                array31[day]++;
            }
        }
    });
    const i:Indication = {
        no_task_date: array31.reduce((acc: number[], value: number, index: number) => {
            if (value == 0 && index != 0) {
                acc.push(index);
            }
            return acc;
        }, []),
        tasks_1_2: array31.reduce((acc: number[], value: number, index: number) => {
            if (value == 1 || value == 2) {
                acc.push(index);
            }
            return acc;
        }, []),
        tasks_3_5: array31.reduce((acc: number[], value: number, index: number) => {
            if (value >= 3 && value <= 5) {
                acc.push(index);
            }
            return acc;
        }, []),
        tasks_6_9: array31.reduce((acc: number[], value: number, index: number) => {
            if (value >= 6 && value <= 9) {
                acc.push(index);
            }
            return acc;
        }, []),
        tasks_10_and_more: array31.reduce((acc: number[], value: number, index: number) => {
            if (value >= 10) {
                acc.push(index);
            }
            return acc;
        }, []),
        days_with_tasks: array31.reduce((acc: number[], value: number, index: number) => {
            if (value > 0) {
                acc.push(index);
            }
            return acc;
        }, []),
    };
    console.log("Indicator", i)
    return i;
}
export default generateMonthIndication;