import ButtonPrimary from "../buttons/ButtonPrimary";
import logo from '../images/logo.png';
export default function SignUpTaskListPage({ familyInfo, letsGo }) {
    // validate data
    // load tasks
    // states: welcome, taskview, pending...

    // foreach tasks (maybe with map)...

    return <div style={{overflowY: 'scroll', overflowX: 'hidden', height: ' calc(100%)'}}>
        <div style={{ width: 180, margin: 'auto', marginTop: 10 }}>
            <img src={logo} style={{ width: 180 }} alt="logo" />
        </div>

        <br />
        <div style={{ padding: 25 }}><i >Some tasks from <b>{familyInfo.title}</b>:</i></div>
        <br />
        <div style={{ height: '40vh', overflowY: 'scroll', margin: 10 }}>

            {familyInfo.tasks.no_time.filter((t) => t.assignee == null).map((t) => <div
                key={t.id}
                style={{ padding: 10, margin: 20, backgroundColor: '#F5F8FF', borderRadius: 10 }}
            >
                <b style={{ textAlign: 'center' }}>{t.title}</b> <br />
                {t.description}
            </div>)}

            {familyInfo.tasks.events.filter((t) => t.assignee == null).map((t) => <div
                key={t.id}
                style={{ padding: 10, margin: 20, borderRadius: 10, backgroundColor: '#F5F8FF' }}
            >
                <b style={{ textAlign: 'center' }}>{t.title}</b> <br />
                {t.description}
            </div>)}
        </div>
        <br />
        <div style={{ padding: 20 }}>
            To preserve the privacy of the family, you should register to this system.
            After registration you can take a task after approvement from the family.
        </div>
        <br />
        <div style={{ width: 250, margin: 'auto' }}>
            <ButtonPrimary onClick={letsGo}>Sign up</ButtonPrimary>
        </div>
        <br/><br/>&nbsp;
    </div>
}