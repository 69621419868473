import logo from '../images/logo.png';
import t from '../translations/translate.ts';
import ButtonPrimary from '../buttons/ButtonPrimary.js';
import { useState } from 'react';
import TextField from '../fields/TextField.js';
import TextFieldType from '../enums/TextFieldTypes.js';
import CONSTS from '../Constants.js';
import axios from 'axios';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';


export default function SignUpForm({ familyInviteToken, familyInfo,
    setFirstName, firstName, setLastName, lastName, setPhoneNumber, phoneNumber,
    letsGo
}) {
    const [phoneCode, setPhoneCode] = useState("+972");

    if (firstName.length >= 80) {
        setFirstName(firstName.substr(0, 79));
        NotificationManager.warning("Text input", "First name length should be less than 80 symbols");
    }
    if (lastName.length >= 80) {
        setLastName(lastName.substr(0, 79));
        NotificationManager.warning("Text input", "Last name length should be less than 80 symbols");
    }

    async function signUpRequest() {
        try {
            if (firstName.length < 2) {
                NotificationManager.warning("Text input", "First name length should be more than 2 symbols");
                return;
            }
            if (lastName.length < 2) {
                NotificationManager.warning("Text input", "First name length should be more than 2 symbols");
                return;
            }
            if (phoneNumber.toString().length <= 4) {
                NotificationManager.warning("Text input", "Phone number length should be more than 4 symbols");
                return;
            }
            const response = await axios.post(`${CONSTS.BASE_API_URL}/supporters/signup`, {
                invitation: familyInviteToken,
                supporter: {
                    first_name: firstName,
                    last_name: lastName,
                    phone_number: phoneCode + phoneNumber,
                }
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            letsGo();
        } catch (error) {
            console.log("error_", error);
            if (error.response) {
                const r = error.response;
                if(r.data.error.includes("phone_number is invalid")){
                    NotificationManager.error(t('pleaseEnterValidPhone'), t('signUpRequestError'));
                }else if(r.data.error.includes("already among family")){
                    NotificationManager.error(t('youAreRegistered'), t('signUpRequestError'));
                }else if(r.data.error.includes("phone_number is invalid")){
                    NotificationManager.error(r.data.error,t('signUpRequestError'));
                }
            }else{
                NotificationManager.error(error.toString(), t('signUpRequestError'));
            }
        }
    }
    return <div style={{ overflowY: 'scroll', overflowX: 'hidden', height: ' calc(100%)' }}>
        <div style={{ width: 290, maxWidth: '90%', margin: 'auto', padding: 0 }}>
            <img src={logo} style={{ width: 290 }} alt="logo" />
        </div>
        <h1 style={{ textAlign: 'center' }}>
            {t('signUp')}
        </h1>
        <div>
            <form style={{ width: 260, maxWidth: '90%', padding: 5, margin: 'auto' }} onSubmit={() => false}>
                <p style={{ fontSize: '19px' }}>
                    {t('wName')}
                </p>
                <p>{t('family')}: <b>{familyInfo.title}</b></p>
                <label>

                    <TextField type={TextFieldType.TEXT}
                        placeholder='First name'

                        name="first_name"
                        value={firstName}
                        onChange={(e) => { setFirstName(e.target.value) }}
                    />
                </label>
                <br />

                <label>

                    <TextField type={TextFieldType.TEXT}
                        placeholder='Last name'
                        name="last_name"
                        value={lastName}
                        onChange={(e) => { setLastName(e.target.value) }}
                    />
                </label>

                <p style={{ fontSize: '19px' }}>
                    {t('wNumber')}
                </p>
                <label>

                    <TextField type={TextFieldType.PHONE}

                        codeValue={phoneCode}
                        onCodeChange={(e) => { setPhoneCode(e.target.value) }}
                        placeholder='Phone number'
                        name="phone_number"
                        value={phoneNumber}
                        onChange={(e) => { setPhoneNumber(e.target.value) }}
                    />
                </label>
                <br />

                <div style={{ paddingTop: '10vh' }}>
                    <input id='sign_up_form_submit' style={{ display: 'none' }} type='submit' />
                    <ButtonPrimary onClick={() => signUpRequest()} >
                        {t('signUp')}
                    </ButtonPrimary>
                </div>
            </form>
        </div>
        <br /><br /><br /><br />
        <NotificationContainer />


    </div>
}