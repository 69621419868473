import './ButtonBluePrimary.css'
import './buttons.css';
const ButtonBluePrimary = ({onClick, children, icon=null}) => {
    return(
        <div onClick={onClick} className='_custom_regular_button ButtonBluePrimary'>
            <table style={{width: '100%', padding: 0, margin: 0}}><tbody><tr>
            <td>{icon? <img style={{height: 15}} src={icon} alt='icon'/>:<></>}</td><td>{children}</td>
            </tr></tbody></table>
        </div>
    )
}
export default ButtonBluePrimary;
