import React, { useState } from 'react';
import TaskModal from "./TaskModal";
import TaskState from './enums/TaskState';
import t from './translations/translate.ts';
import ButtonBlueSecondary from "./buttons/ButtonBlueSecondary";
export default function TaskCardHome({
    //isOpen, onConfirm = () => { }, onCancel = () => { },
    onTaken = () => { },
    taskId = 123,
    isEvent = false,
    isTaken = false,
    taskTime = "16:00",
    timeRange = "16:00 - 18:00",
    taskDate = "11 November",
    taskName = "Appointment with Dr. Cooper",
    taskDescription = "If there is a short comment, it will be located here",
    coordinatorName = "...",
    myTask = false,
    coordinatorNumber = "..."
}) {
    const [isModalOpen, setModalOpen] = useState(false);
    var taskState = isTaken ? TaskState.TAKEN : TaskState.NOT_TAKEN;
    return (<div>
        <TaskModal
            isEvent={isEvent}
            onTaken={() => { setModalOpen(false); onTaken(); }}
            taskId={taskId}
            isOpen={isModalOpen}
            coordinatorName={coordinatorName}
            coordinatorNumber={coordinatorNumber}
            taskTime={taskTime}
            taskName={taskName}
            timeRange={timeRange}
            dateStr={taskDate}

            taskDescription={taskDescription || <i style={{ fontWeight: 100, opacity: 0.6 }}>
                {t('noDescriprion')}
            </i>}
            onCancel={() => { setModalOpen(false) }}
        />
        <div style={{ width: '96%', maxWidth: 400, margin: 'auto' }}>
            <table style={{ width: '96%', margin: 10, marginTop: 20, padding: 10, borderRadius: '14px', 
            backgroundColor: taskState == TaskState.TAKEN ? (myTask?'rgb(247, 218, 203)':'#F0F0F0') : '#F5F8FF' }}>
                <tbody>
                    <tr>
                        <td> <b>{taskTime}</b> </td> <td>{taskName}</td>
                        <td style={{ display: taskState == TaskState.TAKEN ? '' : 'none', width: 1, paddingTop: 4 }}>
                            <div style={{
                                width: 97,
                                border: '2px solid', fontSize: 17, textAlign: 'center',
                                transform: 'rotate(12deg)', fontFamily: 'Rubik Medium'
                                , marginLeft: localStorage.getItem("locale") != "iw" ? -50 : 5,
                                marginRight: localStorage.getItem("locale") == "iw" ? -50 : 5
                                , fontWeight: 1100
                            }}>
                                {t('taken')}
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: 52, paddingTop: 10, verticalAlign: 'bottom',
                     display: taskState == TaskState.TAKEN ? 'none' : '' }}>
                        <td colSpan="2">
                            <ButtonBlueSecondary onClick={() => setModalOpen(true)}>
                                {t('viewDetails')}
                            </ButtonBlueSecondary>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            {myTask ? <p>
                                <b>{t('thisIsYourTask')}</b><br />
                                <a href={"/my_tasks?fromPreview=" + (isEvent?"":"-") + taskId}>Show in my tasks</a>
                            </p> : <div style={{ height: 0 }} />}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>);
}
