import './ButtonBlueSecondary.css';
import './buttons.css';
const ButtonBlueSecondary = ({onClick, children, icon=null}) => {
    return(
        <div onClick={onClick} className='_custom_regular_button ButtonBlueSecondary'>
            {icon? <img src={icon} alt='icon'/>:<></>}
            {children}
        </div>
    )
}
export default ButtonBlueSecondary;
