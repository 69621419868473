import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Wall from './Wall';
//import reportWebVitals from './reportWebVitals';
//import { Route } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import NotFound from './404'
import MyTasks from './MyTasks';
import LoginPage from './LoginPage';
import Loader from './Loader';
import ErrorPage from './ErrorPage';
//import AppUrlListener from './AppUrlListener';
//import { IonApp, IonRouterOutlet } from '@ionic/react';
//import { IonReactRouter } from '@ionic/react-router';
import ZeroTask from './ZeroTasks';
import SignUpPage from './SignUpPage';
import SignUpSuccess from './SignUpSuccess';
import TaskPreview from './TaskPreview';
import DocViewer from './DocViewer';
import SignUpMain from './signUpPages/SignUp';
import ImageHelper from './helpers/ImageHelper';
const root = ReactDOM.createRoot(document.getElementById('root'));

if (localStorage.getItem("locale") == null) {
  const language = navigator.language.toLowerCase();
  if (language.startsWith('he')) {
    localStorage.setItem("locale", "iw");
  } else if (language.startsWith('en')) {
    localStorage.setItem("locale", "en");
  } else if (language.startsWith('de')) {
    localStorage.setItem("locale", "de");
  } else if (language.startsWith('ru')) {
    localStorage.setItem("locale", "ru");
  }
}

root.render(
  <Router>
      <Switch>
        <Route exact path="/login/:user/:family" component={LoginPage} />
        <Route exact path="/t/:family_token/:task_id" component={TaskPreview} />
        <Route exact path="/welcome_page" component={App} />
        <Route exact path="/helper/:helper_path" component={ImageHelper} />
        <Route exact path="/" component={App} />
        <Route exact path="/tasks" component={Wall} />
        <Route exact path="/my_tasks" component={MyTasks} />
        <Route exact path="/loader" component={Loader} />
        <Route exact path="/error" component={ErrorPage} />
        <Route exact path="/zero" component={ZeroTask} />
        <Route exact path="/sign__up/:token" component={SignUpPage} />
        <Route exact path="/sign-up/:token" component={SignUpMain} />
        <Route exact path="/docview/:docPath" component={DocViewer} />
        <Route exact path="/sign_success" component={SignUpSuccess} />

        <Route component={NotFound} status={404} />
     
     </Switch>
     </Router>
);

// sign up
/*

sign up token
/sign__up/:token

*/