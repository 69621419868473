import plane from './images/paper_plane.png';
import t from './translations/translate.ts';
const SignUpSuccess = ({ msg }) => {
    return (<div>

        <div style={{height: 350, paddingTop: '20vh'}}>
        
        <div style={{paddingTop: 20, paddingRight: 20}}>
            <img style={{width: '100%', maxHeight: 190, maxWidth: 900}} src={plane} alt='plane'/>
        </div>
        <p style={{fontSize: '28px', color: '#269ACF', textAlign: 'center'}}>
            {t('happyJourney')}
        </p>
        <p style={{whiteSpace: 'pre'}}>{msg}</p>
        <p style={{fontSize: "19px", margin: 10, textAlign: 'center'}}>
            SMS with login link is <br/>
            on the way
        </p>
        
        </div>
    </div>)
}
export default SignUpSuccess;
