function generateAppleLink(title: string,  startAt: Date, endAt: Date, description: string, location: string,
  taskId: number, familyToken: string
  /*
  to do: task id, task family
  */
  ): string {
    const queryParams = new URLSearchParams({
      title,
      description,
      start: startAt.getTime().toString(),
      end: endAt.getTime().toString(),
      location: location,
      url: `https://web.enolahelp.com/t/${familyToken}/${taskId}`
    });
    return `https://applecalendar.josal28104.workers.dev?${queryParams.toString()}`;
  }
export default generateAppleLink;
