import { TranslationContextProvider } from 'schummar-translate/react';
import BackButton from './buttons/BackButton';
import ButtonPrimary from './buttons/ButtonPrimary';
import notebook_icon from './images/notebook.png';
import t from './translations/translate.ts';
const ZeroTask = ({ }) => {
    return (
        <TranslationContextProvider locale={localStorage.getItem("locale")}>
            <table style={{ direction: localStorage.getItem("locale")=="iw"?'rtl':'ltr', width: '100%', height: '100vh', textAlign: 'center', userSelect: 'none', msUserSelect: 'none', WebkitTouchCallout: 'none' }}>
                <tbody style={{ overflowY: 'scroll', msOverflowY: 'scroll', direction: localStorage.getItem("locale")=="iw"?'rtl':'ltr' }}>
                    <tr style={{ height: '15px' }}></tr>
                    <tr style={{ height: '32px', margin: 0, paddingTop: "24px", direction: localStorage.getItem("locale")=="iw"?'rtl':'ltr' }}>
                        <td style={{ width: '32px', paddingLeft: 8 }}><BackButton /></td>
                        <td style={{ textAlign: 'center', fontSize: '23px', fontWeight: 700 }}>
                            <div style={{ marginLeft: -42 }}>
                                {t('myTasks')}
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '15vh' }}></tr>
                    <tr><td colSpan="2" style={{ fontSize: '22px', fontWeight: 600 }}>
                        {t('noTaskYet')}
                    </td></tr>
                    <tr><td colSpan="2" style={{ fontSize: '18px', fontWeight: 400 }}>
                        {t('anyHelp')}
                    </td></tr>
                    <tr style={{ height: '40vh', padding: '2vh' }}><td colSpan="2">
                        <img src={notebook_icon} alt='notebook' style={{ maxWidth: '90%', height: '40vh' }} />
                    </td></tr>
                    <tr><td style={{ padding: '4%' }} colSpan="2">
                        <div style={{ maxWidth: 400, margin: 'auto' }}><ButtonPrimary onClick={() => {
                            window.location.href = "/tasks";
                        }}>
                            {t('howCanI')}
                        </ButtonPrimary></div>
                    </td></tr>
                    <tr>&nbsp;</tr>
                </tbody>
            </table>
        </TranslationContextProvider>
    )
}
export default ZeroTask;
