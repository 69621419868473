import React from 'react';

const Text28 = ({ children }) => {
  const textStyle = {
    textAlign: 'center',
    color: '#313E44',
    padding: '8px',
    fontSize: '28px',
    fontFamily: 'Space Grotesk',
    fontWeight: 500,
    wordWrap: 'break-word',
  };

  return (
    <div style={textStyle}>
      {children}
    </div>
  );
};

export default Text28;