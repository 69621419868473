import { createTranslator } from 'schummar-translate/react';
import en from './locales/en.ts';
import iw from './locales/iw.ts';
import de from './locales/de.ts';
import ru from './locales/ru.ts';

const translator = createTranslator({
    sourceLocale: 'en',
    dicts: { en, iw, de, ru },
});
//translator.useTranslator("iw");
export default translator.t;