import React, { useState } from 'react';
import './WhiteDropdown.css';
import dropdown_icon from '../images/dropdown.png';
const WhiteDropdown = ({ options, txt, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
    onSelect(value);
  };

  return (
    <div  className="custom-dropdown">
      <div style={{direction: localStorage.getItem("locale") == "iw"?"rtl":"ltr"}} className={`dropdown-header ${isOpen ? 'open' : ''}`} onClick={toggleDropdown}>
        <span>{txt}</span>
        <img style={{position: 'absolute', right: 6, top: 10, transform: isOpen?'rotate(180deg)':''}} src={dropdown_icon} alt='^'/>
      </div>
      {isOpen && (
        <div className="dropdown-content">
          {options.map((option) => (
            <div
              key={option.value}
              className="dropdown-option"
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WhiteDropdown;
