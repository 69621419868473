import React from "react";
import t from './translations/translate.ts';
class ProgressBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        window.addEventListener('resize', () => this.setState({}));
    }
    render() {
        var total = this.props.total;
        var part = this.props.part;
        var isWall = this.props.isWall || false;
        return (

            <div style={{
                height: 32,
                width: isWall? 260:270, margin: "auto", marginTop: 0, padding: 5,
                paddingLeft: isWall?20:'',
                paddingRight: isWall?20:'',
                backgroundColor: 'transparent',
                borderRadius: "12px"
            }}>
                <span style={{ fontSize: 15, marginLeft: "4px", marginRight: "4px", fontWeight: 500, color: "#313E44" }}>
                    {t('progressBar', {part: part, total: total})}
                </span>
                <div style={{ height: 4, width: "96%", marginLeft: "4px",
                marginRight: "4px", marginTop: '10px'
                , borderRadius: 4, backgroundColor: "#E0E9FD",}}>
                    <div style={{ height: 4, borderRadius: 4, width: (100 * part / total) + "%", backgroundColor: "#313E44" }}></div>
                </div>
            </div>

        )
    }
}
export default ProgressBar;