function generateGoogleCalendarURL(eventTitle, startDate, endDate, eventDescription, eventLocation, taskId, familyToken) {
    var url = `https://web.enolahelp.com/t/${familyToken}/${taskId}`
    const baseURL = 'https://www.google.com/calendar/render?action=TEMPLATE';
    var formattedStartDate;
    try {
        formattedStartDate = startDate ? startDate.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z' : '';
    } catch (e) {
        formattedStartDate = new Date().toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
    }
    var formattedEndDate;
    try {
        formattedEndDate = endDate ? endDate.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z' : '';
    } catch (e) {
        formattedEndDate = new Date().toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
    }
    var eventDescription_ = (eventDescription != null && eventDescription != "null") ? eventDescription:"";
    eventDescription_ += "\n\n"+ url;
    const googleCalendarURL = `${baseURL}&text=${encodeURIComponent(eventTitle)}&dates=${formattedStartDate}/${formattedEndDate}&details=${encodeURIComponent(eventDescription_)}&location=${encodeURIComponent(eventLocation)}`;
    return googleCalendarURL;
}
export default generateGoogleCalendarURL;
