export default {
    welcomeBack: 'Добро пожаловать', /* любой текст, это комментарий */
    myTasks: 'Мои задачи',
    helloName: 'Привет, {name}!',
    thanksForFamily: 'Спасибо, что присоединились к поддержке семьи {family_name}.',
    alwaysReady: 'Всегда готовы помочь!',
    progressBar: '{part} из {total} задач взяты',
    allTasks: 'Все задачи',
    datelessTasks: 'Задачи без даты',
    iWillTakeIt: "Возьму это",
    with_date: 'с датой',
    dateless: 'без даты',
    thanksForCaring: 'Словами не передать нашу благодарность. Ваши дела говорят сами за себя. Спасибо!',
    percent38: ' ',
    setSms: 'Напомнить мне СМС',
    addCalendar: 'Добавить в календарь',
    cancel: 'Отмена',
    submit: 'Отправить',
    monday: 'Пн',
    tuesday: 'Вт',
    wednesday: 'Ср',
    thursday: 'Чт',
    friday: 'Пт',
    saturday: 'Сб',
    sunday: 'Вс',
    january: "Январь",
    february: "Февраль",
    march: "Март",
    april: "Апрель",
    may: "Май",
    june: "Июнь",
    july: "Июль",
    august: "Август",
    september: "Сентябрь",
    october: "Октябрь",
    november: "Ноябрь",
    december: "Декабрь",
    translate: "Перевести",
    refuseYesNo: "Вы уверены, что хотите отказаться?",
    refuseIfYes: "Если да, пожалуйста, опишите почему в поле ниже.",
    yourAnswer: "Ваш ответ",
    back: "Назад",
    confirm: "Подтвердить",
    areYouSure: "Вы уверены?",
    didYouComplete: "Вы действительно завершили задачу?",
    yes: "Да",
    no: "Нет",
    noTaskThis: "Нет задач на выбранную дату. Также нет задач без даты",
    noTaskTaken: "Вы не взялись ни за одну задачу. Пожалуйста, перейдите на главную страницу задач и взялитесь за задачи",
    noTaskAvailable: "Нет доступных задач. Пожалуйста, попросите владельца семьи создать задачи для семьи",
    errLoginTitle: "Пожалуйста, войдите",
    errAuthTitle: "Неправильная аутентификация",
    errAuthDescription: "Эта страница требует аутентификации, но сохраненные токены недействительны. Попробуйте войти через СМС снова",
    errLoadTitle: "Ошибка на странице",
    errLoadDescription: "Произошло что-то неожиданное. Пожалуйста, свяжитесь со службой поддержки",
    errNetworkTitle: "Недоступно",
    errNetworkDescription: "Пожалуйста, проверьте ваше интернет-соединение и перезагрузите страницу",
    errServerTitle: "Что-то не так на сервере",
    errServerDescription: "Пожалуйста, попробуйте загрузить позже",
    decline: "Отказаться",
    done: "Выполнено",
    onlyDatelessStr: "Нет задач на текущий день, но вы также можете сделать это сегодня:",
    datelessStr: "Также вы можете сделать это сегодня:",
    viewDetails: "Посмотреть детали",
    taken: "ВЗЯТО",
    noDescriprion: "Без описания",
    tryAgain: "Попробовать снова",
    happyJourney: "Рады, что вы присоединились к нашему пути",
    notForMe: "Не беру",
    mytasks: "Мои задачи",
    signUp: "Зарегистрироваться",
    wNumber: "Какой у вас номер телефона?",
    wName: "Как вас зовут?",
    greatTo: "Рады вас видеть",
    anyHelp: "Любая ваша помощь ценна",
    scrollToAgree: 'Пролистайте, чтобы согласиться',
    ok_scroll: 'ОК',
    howCanI: 'Как я могу помочь',
    anyTaskTake: 'Любая взятая вами задача поможет',
    noTaskYet: 'Пока задач нет',
    welcomeBackName: 'Привет, {first_name}!',
    helpingToFamily: 'Помощь семье ',

    goBack: 'Назад',

    completed_: "Выполнено",
    unassigned_: "Отменено",
    taken_: "Взято",
    notiTaskCompleted: 'Задание "{taskName}" выполнено',
    notiTaskUnassigned: 'Отказ от "{taskName}" принят',
    notiTaskTaken: 'Задание "{taskName}" взято',

    set_: 'Установить',
    pleasePickTime: 'Выберите время',
    pickDate: 'Укажите дату',
    pickTime: 'Укажите время',
    itsTimeFor: "Это время для Apple/Google календаря для",

    signingIn_1: 'Входя в систему вы принимайте ',
    termsOfUse: 'условаия использования',
    signingIn_2: ' и ',
    privacyPolicy:'политику конфиденциальности',
    signingIn_3: ", которые сохраняет достоинство семьи и оптимизируют помощь",

    thisIsYourTask: 'Это ваше задание',

    switchAccounts: 'Переключение аккаунтов',
    switch: 'Выбрать',
    logout: 'Выйти',
    current: 'текущий',
    help: 'Помощь',


    monday_: 'Пнд',
    tuesday_: 'Втр',
    wednesday_: 'Срд',
    thursday_: 'Чтв',
    friday_: 'Птн',
    saturday_: 'Сбт',
    sunday_: 'Вск',

    signUpRequestError: 'Ошибка запроса регистрации',
    pleaseEnterValidPhone: 'Несуществующий номер телефона. Пожалуйста укажите реальный номер телефона',
    youAreRegistered: 'Вы уже зарегистрированы в этой семье. Ищите ссылку для входа в СМС сообщениях',
    family: 'Семья',

} as const;
