import ReactModal from "react-modal";
import ButtonPrimary from "./buttons/ButtonPrimary.js"
import t from './translations/translate.ts';
import ButtonSecondary from "./buttons/ButtonSecondary.js";

export default function CompleteModal(
    { isOpen, onConfirm, onCancel,
        title = t('areYouSure'),
        taskTitle = "Task",
    }
) {
    return (
        <ReactModal
            style={{
                content: {
                    width: 240,
                    height: 200,
                    margin: 'auto',
                    background: '#F5F8FF',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.16)',
                    borderRadius: '12px'
                }
            }}
            isOpen={isOpen}
        >
            <div>
                <div style={{
                    padding: 10,
                    marginBottom: 15,
                    textAlign: 'center',
                    color: '#313E44',
                    fontSize: '20pt',
                    fontFamily: 'Space Grotesk',
                    fontWeight: 500,
                    wordWrap: 'break-word',
                    direction: localStorage.getItem("locale") == "iw"? 'rtl':'ltr'
                }}>{title}</div>
                <div style={{
                    color: '#313A56',
                    fontSize: '15pt',
                    margin: 10,
                    fontFamily: 'Space Grotesk',
                    fontWeight: 300,
                    wordWrap: 'break-word',
                    direction: localStorage.getItem("locale") == "iw"? 'rtl':'ltr'
                }}>{t('didYouComplete')}</div>
                <div style={{paddingTop: 5}}>
                    <table  style={{ width: '100%', paddingTop: 10,
                   
                     margin: 'auto', marginTop: 10 }}>
                        <tbody>
                            <tr style={{ paddingTop: 16, margin: 5, width: '100%' }}>
                                <td style={{ width: "90px" }}>
                                    <div style={{ width: "90px" }}>
                                        <ButtonSecondary onClick={onCancel}>{t('no')}</ButtonSecondary>
                                    </div>
                                </td>
                                <td> &nbsp; </td>
                                <td style={{ width: "110px" }}>
                                    <div style={{ marginLeft: "10%", width: "110px" }}>
                                        <ButtonPrimary onClick={onConfirm}>{t('yes')}</ButtonPrimary>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ReactModal>
    )
}