import React from 'react';

const Text24 = ({ children }) => {
    const textStyle = {
        color: '#313A56',
        'fontSize': '22px',
        'fontFamily': 'Space Grotesk',
        'fontWeight': 300,
        'padding': '4px',
        'wordWrap': 'break-word'
    };

    return (
        <div style={textStyle}>
            {children}
        </div>
    );
};

export default Text24;