import { useState } from "react";
import ButtonPrimary from "./buttons/ButtonPrimary";
import ReactModal from "react-modal";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-time-picker/dist/TimePicker.css';
import './CustomDates.css';
import icon from './images/collapse_icon.png';
import t from './translations/translate.ts';
const INP = ({ onClick, value }) => <div onClick={onClick} children={[<span>{value}</span>, <img style={{ height: 20, position: 'absolute', right: -2, transform: 'rotate(180deg)' }} src={icon} />]} type="text" className="date_str" />;
export default function TaskDateleTimeDialog({
    taskTitle = "Example Task",
    isOpen,
    setDate = (d) => console.log("TaskDateleTimeDialog: selection: ", d)
}) {
    const [selectedDate, setSelectedDate] = useState(new Date().setHours(0, 0, 0));
    const [selectedTime, setSelectedTime] = useState('00:00');
    return <ReactModal
        style={{
            content: {
                width: "230px",
                margin: 'auto',
                height: '375px',
                backgroundColor: '#F5F8FF',
                borderRadius: '9px'
            }
        }}
        isOpen={isOpen}
    >
        <div style={{ width: 230, margin: 'auto', }}>
            <p style={{ textAlign: 'center' }}>{t('pleasePickTime')}</p>
            <p>
                <div style={{ margin: 5 }}>{t('pickDate')}</div>
                <div style={{direction: 'ltr'}}>
                <DatePicker

                    selected={selectedDate}
                    customInput={<INP />}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="MMM dd, yyyy"
                />
                </div>
            </p>
            <p>
                <div style={{ margin: 5 }}>{t('pickTime')}</div>
                <div style={{direction: 'ltr'}}>
                <TimePicker
                    value={selectedTime}
                    onChange={(time) => setSelectedTime(time)}
                    style={{ color: 'red' }}
                    format="HH:mm"
                />
                </div>
            </p>
            <p><span>{t('itsTimeFor')}</span>  <b>{taskTitle}</b></p>
            <p>
                <ButtonPrimary onClick={()=>{
                    var d = new Date(selectedDate);
                    var t_ = selectedTime;
                    d.setHours(t_.split(":")[0], t_.split(":")[1],0,0);
                    setDate(d);
                }}>{t('set_')}</ButtonPrimary>
            </p>
        </div>
    </ReactModal>;
}
