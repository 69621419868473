/*

Here is located modal dialog for Task

View all params

Actions (Pick up, )

params: (task object), isOpend, setOpened, setContentUpdate

action buttons
(on click, ...)

items

header (close button)

body

footer

*/


// using react-modal
// accessible as object
// methods: show, set content, cancel, hide, confirm

import ReactModal from "react-modal";
import cloud_icon from './images/img_cloud_1.png';
import heart_icon from './images/img_heart_1.png';
import face from './images/faces/face_1.png';

import TaskDateleTimeDialog from './TaskDatelessChooseTime';

import t from './translations/translate.ts';
import { useState } from "react";
import TaskState from "./enums/TaskState.js";
import ButtonBlueSecondary from "./buttons/ButtonBlueSecondary.js";
import axios from 'axios';
import ButtonBluePrimary from "./buttons/ButtonBluePrimary.js";
import ButtonIcon from "./enums/ButtonIcons.js";
import ConfirmationModal from "./ConfirmationModal.js";
import generateGoogleCalendarURL from "./GoogleCalendar.js";
import CONSTS from "./Constants.js";
import generateAppleLink from "./AppleCalendar.ts";
import phone_icon from './images/phone_icon.png';

import sms_icon from "./images/sms_icon.png";
import whatsap_icon from "./images/whatsapp_icon.png";

import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

export default function TaskModal(
    { isOpen, onTaken = () => { }, onCancel = () => { },
        taskId = 123,
        isEvent = false,
        dateStr = "",
        timeRange = "(dateless)",
        taskName = "Transportation to hospital",
        taskDescription = "If there is a short comment, it will be located here",
        coordinatorName = "Ifat Edrey",
        coordinatorNumber = "+972 58 5000930"
    }
) {
    function openUrlBlank(url, isBlank = true) {
        var link = document.createElement("a");
        link.href = url;
        link.id = Math.random().toString();
        if (isBlank) link.target = "_blank";
        document.body.appendChild(link);
        link.click();
    }

    const [isConfirmationOpen, setConfirmationOpen] = useState(false);

    //const [isAppleOpen, setisAppleOpen] = useState(false);
    //const [appleCalendarUrl, setAppleCalendarUrl] = useState("about:blank");
    const [timeDialogOpen, setTimeDialogOpen] = useState(false);

    const [isGoogleCalendarEnabled, setGoogleCalendarEnabled] = useState(false);

    return (
        <ReactModal
            style={{
                content: {
                    width: "260px",

                    left: localStorage.getItem("locale") != "iw" ? -10 + ((document.body.offsetWidth - 270) / 2.5) : '',
                    right: localStorage.getItem("locale") == "iw" ? -10 + ((document.body.offsetWidth - 270) / 2.5) : '',
                    height: !isEvent ? "270px" : "290px",
                    margin: 'auto',
                    background: '#F5F8FF',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.16)',
                    borderRadius: '12px'
                }
            }}
            isOpen={isOpen}
        >
            <div>
                <table style={{ width: '100%', padding: 0 }}>
                    <tbody>
                        <tr style={{ fontSize: "15px" }}>
                            <td colSpan="2" style={{ fontSize: 12.5 }} ><b>{dateStr}</b></td>
                            <td style={{
                                textAlign:
                                    localStorage.getItem("locale") == "iw" ? 'left' : 'right'
                                , fontSize: 12.5
                            }}><b>{timeRange.split("-")[0]}</b></td>
                        </tr>

                        <tr>
                            <td colSpan="3" style={{
                                color: '#313E44',
                                paddingTop: 5,
                                paddingBottom: 5,
                                fontSize: '16px',
                                lineHeight: '1.1em',
                                fontWeight: 600,
                            }}>
                                <div style={{ height: '2.2em', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                    {taskName}
                                </div>
                            </td>
                        </tr>
                        <tr style={{}}>
                            <td colSpan="3" style={{
                                color: '#313E44',
                                fontSize: '16px',
                                fontWeight: 300,
                            }}>
                                <div style={{ height: 55, fontSize: 14, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                    {taskDescription}
                                </div>
                            </td>
                        </tr>
                        <tr style={{
                            paddingTop: 15,
                            paddingBottom: 15, height: 46
                        }}>
                            <td style={{ width: "20px", }}><img style={{ marginLeft: 1, marginTop: 14, width: 35, height: 35, padding: 0 }} alt="ava" src={face} /></td>


                            <td colSpan={2} style={{

                                color: '#313E44',
                                fontSize: '16px',
                                fontWeight: 400,
                                textAlign: 'left',
                                paddingLeft: 5,
                                paddingRight: 5,
                                height: 29,
                                paddingTop: 10

                            }}>

                                <div style={{
                                    width: "100%",
                                    verticalAlign: 'center',
                                    fontSize: '14px',
                                    overflow: 'hidden',
                                    margin: 0,
                                    padding: 10,
                                    textOverflow: 'ellipsis',
                                    direction: 'ltr'
                                }}>
                                    {coordinatorName}<br />
                                    <a href={"tel:" + coordinatorNumber.replaceAll(" ", "").replaceAll("-", "")}>{coordinatorNumber}</a>


                                </div>

                            </td>
                        </tr>
                        <tr >
                            <td style={{ userSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none' }} colSpan={3}>



                                <a href={"tel:" + coordinatorNumber.replaceAll(" ", "").replaceAll("-", "")}>
                                    <img width="30" height="30" src={phone_icon} alt="phone_icon" />
                                </a>

                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp;

                                {
                                    (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
                                        || navigator.userAgent.match(/Macintosh/i)) ?

                                        <a href={"sms:" + coordinatorNumber.replaceAll(" ", "").replaceAll("-", "")}>
                                            <img width="30" height="30" src={sms_icon} alt="sms" />
                                        </a>
                                        : <a href={"sms:" + coordinatorNumber.replaceAll(" ", "").replaceAll("-", "") + "?body=" + encodeURIComponent(
                                            `Hello, ${coordinatorName}! I have some questions about task "${taskName}"`)}>
                                            <img width="30" height="30" src={sms_icon} alt="sms" />
                                        </a>
                                }

                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp;

                                <a target="_blank" href={"https://wa.me/" + coordinatorNumber.replaceAll(" ", "").replaceAll("-", "")}>
                                    <img width="30" height="30" src={whatsap_icon} alt="whatsapp--v1" />
                                </a>

                                <br />


                            </td>
                        </tr>

                    </tbody>
                </table>
                <table style={{
                    position: 'absolute', bottom: 10, width: '95%', left: 0,
                    right: 0, paddingLeft: 7, paddingRight: 7
                }}>
                    <tbody>
                        <tr style={{}}>
                            <td style={{ width: '125px', }} >
                                <div style={{ width: '125px', }}>
                                    <ButtonBlueSecondary onClick={onCancel}>
                                        {t('notForMe')}
                                    </ButtonBlueSecondary>
                                </div>
                            </td>
                            <td> &nbsp; </td>
                            <td style={{ width: '135px', }} >
                                <div style={{ width: '135px', }}>
                                    <ButtonBluePrimary onClick={() => setConfirmationOpen(true)}>
                                        {t('iWillTakeIt')}
                                    </ButtonBluePrimary>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <TaskDateleTimeDialog
                    isOpen={!isEvent && timeDialogOpen}
                    taskTitle={taskName}
                    setDate={(selected_date) => {
                        setTimeDialogOpen(false);
                        console.log("time_for_dateless_set", selected_date);
                        var fToken = localStorage.getItem("login_familyToken");
                        var uToken = localStorage.getItem("login_userToken");
                        var urlTasks = `${CONSTS.BASE_API_URL}/supporters/${uToken}/families/${fToken}/tasks/${taskId}/assign_me`;
                        // axios begin
                        axios.put(urlTasks)
                            .then(response => {
                                console.log('[calendar]assign_me request successful:', response.data);
                                if (isGoogleCalendarEnabled) {

                                    var dateStart = new Date(selected_date);
                                    var dateEnd = new Date(selected_date);
                                    if (dateEnd < dateStart) {
                                        dateEnd = new Date(dateStart);
                                    }

                                    if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
                                        || navigator.userAgent.match(/Macintosh/i)) {


                                        window.location.href = (generateAppleLink(
                                            response.data.title,
                                            selected_date,
                                            selected_date,
                                            response.data.description,
                                            "Enola family",
                                            isEvent ? taskId : -(taskId),
                                            localStorage.getItem("login_familyToken")
                                        ));

                                    } else {
                                        window.open(generateGoogleCalendarURL(
                                            response.data.title,
                                            selected_date,
                                            selected_date,
                                            response.data.description,
                                            "Enola family",
                                            isEvent ? taskId : -(taskId),
                                            localStorage.getItem("login_familyToken")
                                        ), '_blank');
                                    }
                                }
                                setConfirmationOpen(false);

                                window.show_notification_success(t('taken_'), t('notiTaskTaken', {taskName: taskName}));
                                // show "notification"
                                //NotificationManager
                                onTaken();
                                
                            })
                            .catch(error => {
                                console.error('assign_me request error:', error);
                                setConfirmationOpen(false);
                            });
                        // axios end
                    }}
                />
                <ConfirmationModal
                    calendarEnabled={isGoogleCalendarEnabled}
                    calendarSetEnabled={setGoogleCalendarEnabled}
                    onCancel={() => {
                        setConfirmationOpen(false);
                    }}
                    onConfirm={() => {

                        var fToken = localStorage.getItem("login_familyToken");
                        var uToken = localStorage.getItem("login_userToken");
                        //
                        var urlTasks = `${CONSTS.BASE_API_URL}/supporters/${uToken}/families/${fToken}/tasks/${taskId}/assign_me`;
                        if (isEvent) {
                            urlTasks = `${CONSTS.BASE_API_URL}/supporters/${uToken}/families/${fToken}/events/${taskId}/assign_me`;
                        }

                        if (isEvent || (!isGoogleCalendarEnabled)) {
                            axios.put(urlTasks)
                                .then(response => {
                                    console.log('[calendar]assign_me request successful:', response.data);
                                    if (isGoogleCalendarEnabled) {

                                        var dateStart = new Date(response.data.date + "T" + response.data.start_at);
                                        var dateEnd = new Date(response.data.date + "T" + response.data.end_at);
                                        if (dateEnd < dateStart) {
                                            dateEnd = new Date(dateStart);
                                        }

                                        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
                                            || navigator.userAgent.match(/Macintosh/i)) {


                                            window.location.href = (generateAppleLink(
                                                response.data.title,
                                                isEvent ? dateStart : new Date(),
                                                isEvent ? dateEnd : new Date(),
                                                response.data.description,
                                                "Enola family",
                                                isEvent ? taskId : -(taskId),
                                                localStorage.getItem("login_familyToken")
                                            ));

                                        } else {
                                            window.open(generateGoogleCalendarURL(
                                                response.data.title,
                                                dateStart,
                                                dateEnd,
                                                response.data.description,
                                                "Enola family",
                                                isEvent ? taskId : -(taskId),
                                                localStorage.getItem("login_familyToken")
                                            ), '_blank');
                                        }
                                    }
                                    window.show_notification_success(t('taken_'), t('notiTaskTaken', {taskName: taskName}));
                                    setConfirmationOpen(false);
                                })
                                .catch(error => {
                                    console.error('assign_me request error:', error);
                                    setConfirmationOpen(false);
                                });
                            //
                            console.log('assign_me DONE');
                            //
                            onTaken();
                            //

                        } else {
                            setConfirmationOpen(false);
                            setTimeDialogOpen(true);
                            console.log("dateless_choose_will_open");
                            //
                        }
                    }
                    }
                    isOpen={isConfirmationOpen} />
            </div>
        </ReactModal>
    )
}