import { useState } from 'react';
import './CollapseContent.css'
import collapse_icon from './images/collapse_icon.png'

export default function CollapseContent({ header, main }) {
    const [open, setOpen] = useState(false);
    return (

        <div className={!open ? "CollapseDetails" : "CollapseDetails CollapseDetailsOpen"}>
            <div open={open} onClick={() => {
                if (open) {
                    setOpen(false);
                } else {
                    setOpen(true);
                }
            }} className="CollapseSummary">
                <table>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: localStorage.getItem("locale") == "iw" ? 'right':'left', overflowX: 'hidden' }}>{header}</td>
                            <td style={{ textAlign: 'right', width: '25px', display: localStorage.getItem("locale") == "iw"?'':'none' }}>
                                <img className='CollapseIconIw' src={collapse_icon} alt={"^"} />
                            </td>
                            <td style={{ textAlign: 'right', width: '25px', display: localStorage.getItem("locale") != "iw"?'':'none'  }}>
                                <img className='CollapseIcon' src={collapse_icon} alt={"^"} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ textAlign: 'left' }} className="CollapseContent">{main}</div>
        </div>

    );
}
