import React from 'react';
import Loader from './Loader';
import CONSTS from './Constants';
const LoginStatus = {
    NOT_CHECKED: 0,
    CHECKED_OK: 1,
    CHECKED_WRONG: 2
}
class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginStatus: LoginStatus.NOT_CHECKED
        }
        this.familyToken = props.match.params.family;
        this.userToken = props.match.params.user;
        console.log(props.match.params);
    }
    componentDidMount() {
        var familyToken = this.familyToken;
        var userToken = this.userToken;
        localStorage.removeItem("demoAccount");
        const url = `${CONSTS.BASE_API_URL}/supporters/${userToken}/families/${familyToken}`;
        fetch(url)
            .then(response => {
                if (response.status == 200) {
                    localStorage.setItem("login_familyToken", familyToken);
                    localStorage.setItem("login_userToken", userToken);
                    response.json().then((family) => {
                        var loginHistoryStorage = JSON.parse(localStorage.getItem("loginHistoryStorage") || "[]");
                        if (loginHistoryStorage.filter((v) => v.login_familyToken == familyToken && v.login_userToken == userToken).length == 0) {
                            loginHistoryStorage.push({
                                familyName: family.title,
                                coordinatorInfo: family.coordinator,
                                login_familyToken: familyToken,
                                login_userToken: userToken
                            });
                        }
                        localStorage.setItem("loginHistoryStorage", JSON.stringify(loginHistoryStorage));
                        this.setState({ loginStatus: LoginStatus.CHECKED_OK });
                    });
                } else {
                    this.setState({ loginStatus: LoginStatus.CHECKED_WRONG });
                }
            });
    }
    render() {
        switch (this.state.loginStatus) {
            case LoginStatus.NOT_CHECKED:
                return Loader();
            case LoginStatus.CHECKED_OK:
                document.location.href = "/";
                return (
                    <p>Redirecting...</p>
                )
            case LoginStatus.CHECKED_WRONG:
                return (
                    <div style={{ textAlign: 'center', paddingTop: '44vh' }}>
                        This link is <b>not valid</b>.<br />
                        Pleace get valid link via SMS
                    </div>
                )
        }
    }

}
export default LoginPage;