import ReactModal from "react-modal";
import ButtonPrimary from "./buttons/ButtonPrimary.js";
//import CustomButton2 from "./CustomButton2";
import CustomTextarea from "./CustomTextarea";
import t from './translations/translate.ts';
import ButtonSecondary from "./buttons/ButtonSecondary.js";

export default function UnassignModal(
    { isOpen, onConfirm, onCancel,
        reason,
        setReason,
        title = t('refuseYesNo'),
        subtitle = t('refuseIfYes'),
    }
) {

    var answ_ = t('yourAnswer');
    return (
        <ReactModal
            style={{
                content: {
                    width: "240px",
                    height: "360px",
                    maxHeight: '90vh',
                    marginLeft: -6000 / document.body.offsetWidth,
                    marginRight: 0,
                    margin: 'auto',
                    background: '#F5F8FF',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.16)',
                    borderRadius: '12px',
                    direction: localStorage.getItem("locale") == "iw" ? 'rtl' : 'ltr'
                }
            }}
            isOpen={isOpen}
        >
            <div>
                <div style={{
                    marginTop: -10,
                    textAlign: 'center',
                    width: '95%',
                    marginBottom: 12,
                    color: '#313E44',
                    fontSize: '18pt',
                    fontFamily: 'Space Grotesk',
                    wordWrap: 'break-word'
                }}>{title}</div>
                <div style={{
                    margin: 12,
                    color: '#313A56',
                    fontSize: '14pt',
                    fontFamily: 'Space Grotesk',
                    wordWrap: 'break-word'
                }}>{subtitle}</div>
                <div style={{ width: "190px", marginLeft: '10px', marginRight: '10px' }}>
                    <CustomTextarea
                        value={reason}
                        setValue={setReason}
                        id="reasonTextInput"
                        placeholder={t('yourAnswer')}
                    />
                </div>
                <div style={{ height: 10 }}></div>
                <div>
                    <table style={{ width: 250, margin: 'auto' }}>
                        <tbody>
                            <tr style={{ paddingTop: 4, width: '100%' }}>
                                <td style={{ width: "80px" }}>
                                    <div style={{ width: "80px" }}>
                                        <ButtonSecondary onClick={onCancel}>{t('back')}</ButtonSecondary>
                                    </div>
                                </td>
                                <td> &nbsp; </td>
                                <td style={{ width: '110px' }}>
                                    <div style={{ width: "110px" }}>
                                        <ButtonPrimary onClick={onConfirm}>{t('confirm')}</ButtonPrimary>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ReactModal>
    )
}