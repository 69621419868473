import ReactModal from "react-modal";
import './ProfileModal.css';
//import close_icon from "./images/close_30.png";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ButtonSecondary from "./buttons/ButtonSecondary";
import user_default from './images/user_default.png';
import t from './translations/translate.ts';
//import ButtonPrimary from "./buttons/ButtonPrimary";
import WhiteDropdown from './buttons/WhiteDropdown.js';

import help_icon from './images/help_button_icon.svg';
import logout_icon from './images/logout_button_icon.svg';
import language_icon from './images/language_icon.svg';

export default function ProfileModal({
    isOpen = false, fullName = "J", userPhone = "+1234567890", close = () => null,
    setLocale = (locale) => { console.log("locale set", locale)}
}) {
    var loginHistoryStorage = JSON.parse(localStorage.getItem("loginHistoryStorage") || "[]");

    function generateSubscriptionLink() {
        var family = localStorage.getItem("login_familyToken") || "_demo_";
        var user = localStorage.getItem("login_userToken") || "_demo_";
        var link = 'https://ludenlabs.com/enola_apple_calendar_subscription/';
        link += `${family}/${user}/taken/subscrition.ics`;
        return link;
    }
    const isApple = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/Macintosh/i));

    return <ReactModal className="" style={{
        content: {
            padding: 0,
            borderRadius: 15,
            height: (false && isApple) ? 460 : 420,
            width: 300,
            margin: 'auto'
        }
    }}
        isOpen={isOpen}>


        <div style={{ float: 'right', height: 40 }}>
            <button onClick={() => close()} style={{
                margin: 10,
                borderRadius: 10, width: 36,
                border: 'none', cursor: 'pointer',
                height: 36, padding: 0,
            }}>
                <span style={{ fontSize: 32, lineHeight: 0.9 }}>&times;</span>
            </button>
        </div>
        

        <div style={{ width: 240, height: 50, padding: 10, paddingBottom: 15 }}>
            <table><tbody><tr>
                <td> <img style={{ width: 35, height: 35, borderRadius: 20 }} src={user_default} /> </td>
                <td style={{ padding: 15 }}>
                    <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}><b>{fullName}</b></div>
                    <div style={{ whiteSpace: 'nowrap', direction: 'ltr', fontSize: '13.5px' }}>{userPhone}</div>
                </td>
            </tr></tbody></table>
        </div>

        <div
            style={{
                textAlign: 'left',
                margin: '0',
                marginTop: '8px',
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingRight: '15px',
                paddingLeft: '15px',
                verticalAlign: 'center',
                fontSize: '17px',
                fontWeight: '600',
                lineHeight: '18px',
                backgroundColor: '#fff',
                borderColor: '#fff',
                border: '0',
                cursor: 'pointer',
                boxShadow: 'none'
            }}>
            <img src={language_icon} />
            &nbsp; &nbsp; &nbsp;
            <span style={{ top: -5, position: 'relative' }}> Language 
            &nbsp;
            <WhiteDropdown
                txt={ (localStorage.locale == "iw" ? "He" : (localStorage.locale.split('')[0].toUpperCase() + localStorage.locale.split('')[1]))}
                options={[
                    { label: 'He', value: 'iw' },
                    { label: 'En', value: 'en' },
                    { label: 'De', value: 'de' },
                    { label: 'Ru', value: 'ru' },
                ]}
                onSelect={(value) => {
                    localStorage.setItem("locale", value);
                    setLocale(value);
                }}
            /></span>
        </div>

        <div style={{ fontWeight: 700 }}>
            &nbsp; &nbsp; {t('switchAccounts')} &nbsp; &nbsp;
        </div>

        <div className="familyList">

            {loginHistoryStorage.map((v, i) => <div className="familyListItem">
                <b style={{ paddingRight: 10, paddingLeft: 10 }}>{v.familyName}</b>

                {!(v.login_familyToken == localStorage.getItem("login_familyToken")) ?
                    <div style={{ width: 90, height: 40, float: 'right' }}><ButtonSecondary onClick={() => {
                        localStorage.setItem("login_familyToken", v.login_familyToken);
                        localStorage.setItem("login_userToken", v.login_userToken);
                        localStorage.removeItem("demoAccount");

                        window.location.reload();
                    }}>{t('switch')}</ButtonSecondary></div> :
                    <>
                        <div style={{ float: 'right' }}> <i>{t('current')}</i> </div>
                    </>
                }
            </div>)}
        </div>

        {
            (false && isApple) ? <>
                <div className="ctr">
                    Apple calendar subscription
                </div>

                <div style={{ padding: 10, display: 'none' }}>
                    <input id="appleCalendarPromtDisable" type="checkbox" />
                    <label htmlFor="appleCalendarPromtDisable">
                        Don't show "add  to calendar" on task with date taken
                    </label>
                    <br />
                </div>

                <table className="ProfileMenuTable">
                    <tbody>

                        <tr>
                            <td colspan="2" onClick={() => {
                                navigator.clipboard.writeText(generateSubscriptionLink());
                                NotificationManager.warning("Subscription link copied. Please paste it to Apple Calendar (View tutorial via \"How to use\" button)", "Clipboard", 4200);
                            }}>
                                Copy subscription link
                            </td>
                        </tr>

                        <tr>
                            <a id="how_to_use_link" style={{ display: 'none' }} href="/helper/ios_calendar_subscription"></a>
                            <td onClick={() => { document.getElementById('how_to_use_link').click(); }}>How to use</td>
                            <td onClick={() => NotificationManager.info(<>
                                1. No need to add task with date to calendar manually <br /> <br />
                                2. Can be viewed taken tasks from different families in 1 place
                            </>)}>Advantages</td>
                        </tr>
                    </tbody>
                </table>

            </> : <></>}


        <br />

        <button
            onClick={() => {
                NotificationManager.info("Help page is not created yet :(");
            }}
            style={{
                textAlign: 'left',
                width: '50%',
                margin: '0',
                marginTop: '8px',
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingRight: '15px',
                paddingLeft: '15px',
                verticalAlign: 'center',
                fontSize: '17px',
                fontWeight: '600',
                lineHeight: '18px',
                backgroundColor: '#fff',
                borderColor: '#fff',
                border: '0',
                cursor: 'pointer',
                boxShadow: 'none'
            }}>
            <img src={help_icon} />
            &nbsp;&nbsp;
            <span style={{ top: -5, position: 'relative' }}>{t('help')}</span>
        </button>

        <br />

        <button
            onClick={() => {
                localStorage.removeItem("login_familyToken");
                localStorage.removeItem("demoAccount");
                window.location.reload();
            }}
            style={{
                textAlign: 'left',
                width: '50%',
                margin: '0',
                marginTop: '8px',
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingRight: '15px',
                paddingLeft: '15px',
                verticalAlign: 'center',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '18px',
                backgroundColor: '#fff',
                borderColor: '#fff',
                border: '0',
                cursor: 'pointer',
                boxShadow: 'none'
            }}>
            <img src={logout_icon} />
            &nbsp;&nbsp;
            <span style={{ top: -5, position: 'relative' }}>{t('logout')}</span>
        </button>



        <table style={{ display: 'none' }} className="">
            <tbody>

                <tr>
                    <td onClick={() => {
                        NotificationManager.info("Help page is not created yet :(")
                    }}>
                        {t('help')}
                    </td>
                    <td onClick={() => {
                        localStorage.removeItem("login_familyToken");
                        localStorage.removeItem("demoAccount");
                        window.location.reload();
                    }}>{t('logout')}</td>
                </tr>
            </tbody>
        </table>


        <NotificationContainer />
    </ReactModal>;
}

/*

<div className="familyListItem">🖼 <b>Alexa's family</b>   <div style={{width: 90, float: 'right'}}><ButtonSecondary>Switch</ButtonSecondary></div></div>
            <div className="familyListItem">🖼 <b>John Do's family</b> <div style={{width: 90, float: 'right'}}><ButtonSecondary>Switch</ButtonSecondary></div></div>
            <div className="familyListItem">🖼 <b>Eve Li's family</b>  <div style={{width: 90, float: 'right'}}><ButtonSecondary>Switch</ButtonSecondary></div></div>

<div className="familyListItem familyListItemCurrent">
            🖼 <b>John's family</b> <i>(selected)</i> <br/>
            Me: Firstname Lastname <br/>
            Coordinator: Coordinator's name, +1234568798 <br/>
            </div>

            
*/