// using react-modal
// accessible as object
// methods: show, set content, cancel, hide, confirm

import ReactModal from "react-modal";
import ButtonPrimary from "./buttons/ButtonPrimary.js"
import cloud_icon from './images/img_cloud_1.png';
import heart_icon from './images/img_heart_1.png';

import t from './translations/translate.ts';
import ButtonSecondary from "./buttons/ButtonSecondary.js";

export default function ConfirmationModal(
    { isOpen, onConfirm, onCancel,
        calendarEnabled,
        calendarSetEnabled,
        title = t('thanksForCaring'),
        subtitle ='',
        otherElements = [
            <p>
                <input style={{ userSelect: 'none', cursor: 'pointer' }} checked={calendarEnabled} onChange={(e) => { calendarSetEnabled(!calendarEnabled); }} id="confirm_dialog_calendar_check" type="checkbox" />
                <label style={{ userSelect: 'none', cursor: 'pointer' }} for="confirm_dialog_calendar_check">{t('addCalendar')}</label>
            </p>
        ] }
) {
    console.log("ConfirmationModal is open", isOpen);
    return (
        <ReactModal
            style={{
                content: {
                    width: 310,
                    maxWidth: '70%',
                    height: document.body.offsetWidth > 500 ? 325 : 380,
                    margin: 'auto',
                    background: '#F5F8FF',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.16)',
                    borderRadius: '12px'
                }
            }}
            isOpen={isOpen}
        >
            <div>
                <div style={{ height: 0 }}></div>
                <table style={{ width: '100%' }}><tbody><tr>
                    <td style={{ width: '30%' }}><img alt="cloud" style={{ width: 57 }} src={cloud_icon} /></td>
                    <td style={{ textAlign: 'center' }}><img alt="heart" style={{ width: 42 }} src={heart_icon} /></td>
                    <td style={{ width: '30%' }}> &nbsp; </td>
                </tr></tbody></table>
                <div style={{
                    margin: 12,
                    color: '#313E44',
                    fontSize: '17px',
                    fontFamily: 'Space Grotesk',
                    fontWeight: 700,
                    wordWrap: 'break-word'
                }}>{title}</div>
                <div style={{
                    color: '#313A56',
                    fontSize: '15.5px',
                    fontFamily: 'Space Grotesk',
                    fontWeight: 300,
                    wordWrap: 'break-word'
                }}>{subtitle}</div>
                
                <div>
                    <table style={{ width: '100%' }}><tbody><tr>

                        <td>{otherElements}</td>
                        <td style={{ width: 80, marginTop: 10 }}>
                            <img style={{ width: 80 }} alt="cloud" src={cloud_icon} />
                        </td>

                    </tr></tbody></table>
                </div>
                
                <div style={{ display: 'inline' }}>

                    <table style={{ width: '100%', padding: "4px" }}>
                        <tr>

                            <td style={{ width: '90px' }}><div style={{ width: '90px' }}>
                                <ButtonSecondary onClick={onCancel}>{t('cancel')}</ButtonSecondary>
                            </div></td>
                            <td > &nbsp; </td>
                            <td style={{ width: '90px' }}><div style={{ width: '90px' }}>
                                <ButtonPrimary onClick={onConfirm}>{t('submit')}</ButtonPrimary>
                            </div></td>

                        </tr>
                    </table>

                </div>
            </div>
        </ReactModal>
    )
}