class DemoAccount {
    constructor() {
        const storedData = localStorage.getItem('demoAccount');
        if (storedData) {
            const { no_time, events, family, supporter } = JSON.parse(storedData);
            this.no_time = no_time;
            this.events = events;
            this.family = family;
            this.supporter = supporter;
            console.log("no_time", no_time);
            console.log("events", events);
        } else {
            this.family = {
                "title": "The Demo Family",
                "tasks_counter": { "initial": 2, "in_progress": 2, "completed": 0 },
                "coordinator": { "full_name": "Demo coordinator", "formatted_phone_number": "+00-000-000-00-00" },
                "primary_caregiver": { "full_name": "Demo caregiver", "formatted_phone_number": "+00-000-000-00-00" }
            };
            this.supporter = { "full_name": "Example User", "formatted_phone_number": "+00-000-000-00-00" };
            this.no_time = [
                {
                    "id": 1,
                    "type": "no_time",
                    "title": "Task name",
                    "description": "This a is task decsription",
                    "status": "initial",
                    "assigned_on_me": false,
                    "assignee": null,
                    "start_at": null, "end_at": null
                },
                {
                    "id": 2,
                    "type": "no_time",
                    "title": "Varius vel pharetra",
                    "description": "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                    "status": "in_progress",
                    "assigned_on_me": true,
                    "assignee": {
                        "full_name": "Example User",
                        "formatted_phone_number": "+00-000-000-00-00"
                    },
                    "start_at": null, "end_at": null
                }
            ];
            this.events = [
                {
                    "id": 4,
                    "type": "until_time",
                    "title": "Fusce ut placerat",
                    "description": "Aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc sed. Volutpat odio facilisis mauris sit amet massa.",
                    "status": "in_progress",
                    "task_id": 5,
                    "assigned_on_me": true,
                    "assignee": {
                        "full_name": "Example User",
                        "formatted_phone_number": "+00-000-000-00-00"
                    },
                    "date": "2024-02-26",
                    "start_at": "22:00:00+0000",
                    "end_at": "22:00:00+0000"
                },
                {
                    "id": 6,
                    "type": "until_time",
                    "title": "Nam aliquam",
                    "description": "Consequat semper viverra nam libero justo. Varius vel pharetra vel turpis nunc eget",
                    "status": "initial",
                    "task_id": 7,
                    "assigned_on_me": false,
                    "assignee": null,
                    "date": "2024-02-26",
                    "start_at": "09:00:04+0000",
                    "end_at": "09:00:04+0000"
                },
                {
                    "id": 8,
                    "type": "until_time",
                    "title": "Massa ultricies",
                    "description": "Eget magna fermentum iaculis eu non diam phasellus vestibulum. In mollis nunc sed id.",
                    "status": "initial",
                    "task_id": 9,
                    "assigned_on_me": false,
                    "assignee": null,
                    "date": "2024-02-27",
                    "start_at": "07:41:23+0000",
                    "end_at": "07:41:23+0000"
                },
            ];

            this.saveToLocalStorage();
        }
    }
    assign_task(id) {
        for (var i = 0; i < this.no_time.length; i++) {
            const t = this.no_time[i];
            if (t.id === id) {
                t.status = "in_progress";
                t.assigned_on_me = true;
                t.assignee = {
                    "full_name": "Example User",
                    "formatted_phone_number": "+00-000-000-00-00"
                };
                return true;
            }
        }
        return false;
    }
    assign_event(id) {
        for (var i = 0; i < this.events.length; i++) {
            const t = this.events[i];
            if (t.id === id) {
                t.status = "in_progress";
                t.assigned_on_me = true;
                t.assignee = {
                    "full_name": "Example User",
                    "formatted_phone_number": "+00-000-000-00-00"
                };
                return true;
            }
        }
        return false;
    }
    unassign_task(id) {
        for (var i = 0; i < this.no_time.length; i++) {
            const t = this.no_time[i];
            if (t.id === id) {
                t.status = "initial";
                t.assigned_on_me = false;
                t.assignee = null;
                return true;
            }
        }
        return false;
    }
    unassign_event(id) {
        for (var i = 0; i < this.events.length; i++) {
            const t = this.events[i];
            if (t.id === id) {
                t.status = "initial";
                t.assigned_on_me = false;
                t.assignee = null;
                return true;
            }
        }
        return false;
    }
    complete_task(id) {
        for (var i = 0; i < this.no_time.length; i++) {
            const t = this.no_time[i];
            if (t.id === id) {

                return true;
            }
        }
        return false;
    }
    complete_event(id) {
        for (var i = 0; i < this.events.length; i++) {
            const t = this.events[i];
            if (t.id === id) {

                return true;
            }
        }
        return false;
    }
    uncomplete_task(id) {
        for (var i = 0; i < this.no_time.length; i++) {
            const t = this.no_time[i];
            if (t.id === id) {

                return true;
            }
        }
        return false;
    }
    uncomplete_event(id) {
        for (var i = 0; i < this.events.length; i++) {
            const t = this.events[i];
            if (t.id === id) {

                return true;
            }
        }
        return false;
    }
    reset_demo() {
        localStorage.removeItem('demoAccount');
    }
    logout_demo() {
        localStorage.removeItem('demoAccount');
    }
    saveToLocalStorage() {
        localStorage.setItem('demoAccount', JSON.stringify({
            supporter: this.supporter,
            family: this.family,
            no_time: this.no_time,
            events: this.events
        }));
    }
}
export default DemoAccount;
// Example usage:
// const demoAccount = new DemoAccount();
