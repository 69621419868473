const SignUpState = {
    INITIAL: -1,
    VALIDATION_ERROR: -2,
    WELCOME: 0,
    TASK_LIST: 1,
    SIGN_UP_FORM: 2,
    SIGN_UP_PENDING: 3,
    SIGN_UP_FORM_ERROR: 4
}
export default SignUpState;
