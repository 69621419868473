import React, { useState } from 'react';
import axios from 'axios';
import Loader from './Loader';
import CONSTS from './Constants';
import ButtonPrimary from './buttons/ButtonPrimary';
import logo from './images/logo.png';
import TextField from './fields/TextField';
import TextFieldType from './enums/TextFieldTypes';
import ErrorPage from './ErrorPage';
import LOAD_ERROR_TYPES from './enums/LoadErrorTypes';
import SignUpSuccess from './SignUpSuccess';
import t from './translations/translate.ts';
const SignUpPage = ({ match }) => {
    const signUpToken = match.params.token;
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        phone_code: '+972',
    });
    const [formState, setFormState] = useState({
        status: 'pre_initial', // 'pre_initial', 'wrong_token'  // 'initial', 'sending', 'success', 'error'
        message: '',
    });
    const validateToken = async () => {
        try {
            const response = await axios.post(`${CONSTS.BASE_API_URL}/supporters/signup/validate`, {
                "invitation": signUpToken,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.data === 200) {
                setFormState({ status: 'initial', message: 'token validated' });
            }
        } catch (e) {
            try {
                if (typeof e.response.status == 'number') {
                    setFormState({ status: 'wrong_token', message: 'invalid token' });
                }
            } catch (e) {
                setFormState({ status: 'network_error', message: 'web app can not connect to the server' });
            }
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        console.log(name, value);
    };
    var the_phone = ((formData.phone_code || "+972") + formData.phone_number).replaceAll("null", "+972");
    console.log("the_phone", the_phone);
    const sendTheForm = async () => {
        setFormState({
            status: 'sending',
            message: 'Sending request...',
        });
        try {
            const response = await axios.post(`${CONSTS.BASE_API_URL}/supporters/signup`, {
                invitation: signUpToken,
                supporter: {
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    phone_number: the_phone,
                }
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setFormState({
                status: 'success',
                message: "You are registered!\nName: " + response.data.full_name + "\nPhone: " + response.data.formatted_phone_number + "",
            });
        } catch (error) {
            setFormState({
                status: 'error',
                message: 'An error occurred. Please try again.',
            });
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        sendTheForm();
        return false;
    };
    if (formState.status === 'pre_initial') {
        validateToken();
    } else if (formState.status === 'wrong_token') {
        return <ErrorPage errType={LOAD_ERROR_TYPES.WRONG_SIGN_UP_TOKEN} />;
    } else if (formState.status === 'initial') {
        return (
            <div>
                <div style={{ width: 300, maxWidth: '90%', margin: 'auto', padding: 0 }}>
                    <img src={logo} style={{ width: 300 }} alt="logo" />
                </div>
                <h1 style={{ textAlign: 'center' }}>
                    {t('signUp')}
                </h1>
                <form style={{ width: 220, maxWidth: '90%', padding: 5, margin: 'auto' }} onSubmit={handleSubmit}>
                    <p style={{ fontSize: '19px' }}>
                        {t('wName')}
                    </p>
                    <label>

                        <TextField type={TextFieldType.TEXT}
                            placeholder='First name'

                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                        />
                    </label>
                    <br />

                    <label>

                        <TextField type={TextFieldType.TEXT}
                            placeholder='Last name'
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                        />
                    </label>

                    <p style={{ fontSize: '19px' }}>
                        {t('wNumber')}
                    </p>
                    <label>

                        <TextField type={TextFieldType.PHONE}

                            codeValue={formData.phone_code}
                            onCodeChange={handleChange}
                            placeholder='Phone number'
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                        />
                    </label>
                    <br />

                    <div style={{ paddingTop: '10vh' }}>
                        <input id='sign_up_form_submit' style={{ display: 'none' }} type='submit' />
                        <ButtonPrimary onClick={() => document.getElementById("sign_up_form_submit").click()} >
                            {t('signUp')}
                        </ButtonPrimary>
                    </div>
                </form>
                <br />
                <br />
            </div>
        );
    }
    else if (formState.status === 'sending') {
        return Loader();
    }
    else if (formState.status === 'success') {
        window.history.pushState({}, "", "/sign-up/_success_");
        return <SignUpSuccess msg={formState.message}/>;
    }
    else if (formState.status === 'error') {
        return <div>
            <h1>ERROR</h1>
            <p>
                <ButtonPrimary onClick={() => setFormState({ status: 'initial', message: 'Try again' })}>
                    {t('tryAgain')}
                </ButtonPrimary>
            </p>
        </div>;
    } else if (formState.status === 'network_error') {
        return <ErrorPage errType={LOAD_ERROR_TYPES.NETWORK_ERROR} />
    }
    return Loader();
};

export default SignUpPage;
