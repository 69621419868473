export default {
    welcomeBack: 'Welcome back', /* any text, it is a comment */
    myTasks: 'My tasks',
    helloName: 'Hello {name}!',
    thanksForFamily: 'Thanks for joining to support {family_name} family.',
    alwaysReady: 'Always ready to help!',
    progressBar: '{part} out of {total} tasks have been taken',
    allTasks: 'All tasks',
    datelessTasks: 'Dateless tasks',
    iWillTakeIt: "I'll take it!",
    with_date: 'with date',
    dateless: 'dateless',
    thanksForCaring: 'Words cannot express our appreciation. Your deeds speak for themselves. Thanks!',
    percent38: ' ',
    setSms: 'SMS me a reminder',
    addCalendar: 'Add to calendar',
    cancel: 'Cancel',
    submit: 'Submit',
    monday: 'Mo',
    tuesday: 'Tu',
    wednesday: 'We',
    thursday: 'Th',
    friday: 'Fr',
    saturday: 'Sa',
    sunday: 'Su',

    monday_: 'Mon',
    tuesday_: 'Tue',
    wednesday_: 'Wed',
    thursday_: 'Thr',
    friday_: 'Fri',
    saturday_: 'Sat',
    sunday_: 'Sun',

    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",

    translate: "Translate",
    refuseYesNo: "Are you sure you want to refuse?",
    refuseIfYes: "If yes, please describe why in the box below.",
    yourAnswer: "Your answer",
    back: "Back",
    confirm: "Confirm",
    areYouSure: "Are you sure?",
    didYouComplete: "Did you actually complete the Task?",
    yes: "Yes",
    no: "No",

    noTaskThis: "No tasks with selected date available. Also no dateless tasks",
    noTaskTaken: "You haven't taken on any tasks. Please go to main task page and take some tasks",
    noTaskAvailable: "No task available. Please ask family owner to create tasks for the family",

    errLoginTitle: "Please login",
    //errLoginDescription: "",
    errAuthTitle: "Wrong auth",
    errAuthDescription: "This page requires authentification but saved tokens are not valid. Try log in via SMS again",
    errLoadTitle: "Error on page",
    errLoadDescription: "Something unexoected hapend. Please contact support",
    errNetworkTitle: "Not available",
    errNetworkDescription: "Please check your Internet connection and reload page",
    errServerTitle: "Something wrong on server",
    errServerDescription: "Please try load later",

    decline: "Decline",
    done: "Done",
    onlyDatelessStr: "No task for current day, but also you can do this today:",
    datelessStr: "Also you can do this today:",
    viewDetails: "View details",
    taken: "TAKEN",
    noDescriprion: "No description",
    tryAgain: "Try again",
    happyJourney: "Happy you joined\nour journey",
    notForMe: "Quit",
    mytasks: "My tasks",
    signUp: "Sign Up",
    wNumber: "What's your phone number?",
    wName: "What's your name?",
    greatTo: "Great to have you here",
    anyHelp: "Any help you provide,\nwill make a difference",
    scrollToAgree: 'Scroll to agree',
    ok_scroll: 'OK',

    howCanI: 'How can I help',
    anyTaskTake: 'Any task you take will help',
    noTaskYet: 'No tasks yet',

    welcomeBackName: 'Hello {first_name}!',
    helpingToFamily: ' Supporting ',

    goBack: 'Go back',

    completed_: "Completed",
    unassigned_: "Unassigned",
    taken_: "Taken",
    notiTaskCompleted: 'Task "{taskName}" completed',
    notiTaskUnassigned: 'Task "{taskName}" unassigned',
    notiTaskTaken: 'Task "{taskName}" taken',

    set_: 'Set',
    pleasePickTime: 'Please pick time',
    pickDate: 'Pick date',
    pickTime: 'Pick time',
    itsTimeFor: "It's time for Apple/Google Calendar for",

    signingIn_1: 'Signing in constitutes confirmation of the ',
    termsOfUse: 'terms of use',
    signingIn_2: ' and the ',
    privacyPolicy:'privacy policy',
    signingIn_3: ", which preserve the family's dignity and optimize the help",
    thisIsYourTask: 'This is your task',

    switchAccounts: 'Switch accounts',
    switch: 'Switch',
    logout: 'Logout',
    current: 'current',
    help: 'Help',

    signUpRequestError: 'Sign up request error',
    pleaseEnterValidPhone: 'Please enter valid existing phone number',
    youAreRegistered: 'You are already registered in the family. Please find and click on login link in SMS',
    family: 'Family',

} as const;
