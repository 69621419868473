import React, {useEffect} from 'react';

const CustomTextarea = ({ placeholder, id, value, setValue }) => {
  const textareaStyle = {
    width: '100%',
    border: '1px #C8DDE1 solid',
    padding: '12px 14px 12px 14px',
    height: '150px',
    resize: 'none',
    overflow: 'hidden',
    fontSize: '14px',
    outline: 'none',
    fontFamily: 'Space Grotesk',
    borderRadius: 10,
    color: 'rgb(49, 58, 86)',
    placeholder: {
      color: '#999',
    },
  };
  useEffect(() => {
    
    setTimeout(() => document.getElementById(id).setAttribute("placeholder",
     document.getElementById("dxqchyixuwrcwxrzikjp").innerText),150);
  },[]);

  return (
    <div>
      <p style={{display: 'none'}} id='dxqchyixuwrcwxrzikjp'>{placeholder}</p>
      <textarea
        id={id}
        value={value}
        onInput={(e)=> setValue( e.target.value) }
        maxLength="200"
        autoFocus={true}
        style={textareaStyle}
      ></textarea>
    </div>
    
  );
};

export default CustomTextarea;
