import { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Loader from "../Loader";
import axios from 'axios';
import BackButton from "../buttons/BackButton";

export default function ImageHelper({ match }) {
  
  const [helperConfig, setHelperConfig] = useState(null);
  const helperPath = match.params.helper_path;

  useEffect(() => {
    async function a() {
      if(helperConfig == null){
        const d = (((await axios.get(`/helpers/${helperPath}/content.json`)).data));
        console.log("helper data", d);
        setHelperConfig(d);
      }
    }
    a();
  });
  if (helperConfig == null){
    return <Loader/>
  }
  const images = helperConfig.slides.map((slide, index, arr)=> { return {
    original: `/helpers/${helperPath}/slide_images/${slide.file_name}`,
    thumbnail: `/helpers/${helperPath}/thumb_slide_images/${slide.thumb_file_name}`,
    description: slide.slide_description
  }});
  
  return <div style={{overflow: 'scroll', height: '100vh', padding: 15}}>
    <BackButton/>
    <br />
    <h3 style={{ textAlign: 'center' }}> {helperConfig.title} </h3>
    <br />
    <div> {helperConfig.description} </div>
    <br />
    <div style={{maxWidth: 280, margin: 'auto'}}><ImageGallery items={images} /> </div>
    <br />

    <br /><br /><br />

  </div>
}