import './App.css';
import ButtonPrimary from "./buttons/ButtonPrimary.js";
import logo from './images/logo.png';
import { TranslationContextProvider } from 'schummar-translate/react';
import t from './translations/translate.ts';
export default function NotFound() {
    document.title = "404 | Not found";
    const locale = localStorage.getItem("locale") || 'en';
    return (
        <div className='AppBody' style={{
        }}>
            <TranslationContextProvider locale={locale}>
            <table style={{ width: '100%' }}>
                <tbody style={{
                    textAlign: 'center',
                    height: '95vh',
                    width: '100%'
                }}>
                    <tr style={{ height: "10vh" }}>
                        <td>&nbsp;</td>
                    </tr>
                    <tr style={{ height: "10vh" }}>
                        <td>
                            <img src={logo} className="App-logo" alt="logo" />
                        </td>
                    </tr>
                    <tr style={{ height: '200px', position: 'relative' }}>
                        <td>
                            <div style={{
                                textTransform: 'capitalize',
                                zIndex: 1,
                                fontSize: 100,
                                position: 'absolute',
                                top: 0,
                                letterSpacing: '2px',
                                left: 0,
                                color: "#313A561A",
                                width: '100%',
                                fontSize: 120,
                                textAlign: 'center'
                            }}>ERROR</div>
                            <div style={{
                                textTransform: 'capitalize',
                                position: 'absolute',
                                top: 30,
                                letterSpacing: '2px',
                                left: 0,
                                width: '100%',
                                zIndex: 2,
                                fontSize: 120,
                                textAlign: 'center'
                            }}>40<div style={{
                                transform: 'rotate(180deg)',
                                display: 'inline-block'
                            }}>4</div></div>
                        </td>
                    </tr>
                    <tr style={{ height: '10vh' }}>
                        <td>
                            <div style={{ width: 120, margin: 'auto' }}>
                                <ButtonPrimary
                                    onClick={() => {window.history.back(); window.location = "/";}}
                                >{t('goBack')}</ButtonPrimary>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
            </TranslationContextProvider>
        </div>);
}
