import logo from './images/logo.png';
import './Wall.css';
import TheCalendar from './TheCalendar';
import React from 'react';
import UserProfile from './UserProfile';
import axios from 'axios';
import t from './translations/translate.ts';
import { TranslationContextProvider } from 'schummar-translate/react';
import ConfirmationModal from './ConfirmationModal';
import CompleteModal from './CompleteModal';
import UnassignModal from './UnassignModal';
import Loader from './Loader';
import TaskDateType from './enums/TaskDateType';
import generateMonthIndication from './Indication.ts';
import ErrorPage from './ErrorPage';
import LOAD_ERROR_TYPES from './enums/LoadErrorTypes';
import TaskCardHome from './TaskCardHome.js';
import TaskDayList from './TaskDayList.js';

import LogoutButton from './buttons/LogoutButton.js';
import ButtonSecondary from './buttons/ButtonSecondary.js';
import isDemoEnabled from './demo_mode/is_demo_enabled.js';
import DemoAccount from './demo_mode/demo_account.js';
import CONSTS from './Constants.js';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import UserMenuButton from './buttons/UserMenuButton.js';
import ProfileModal from './ProfileModal.js';
import CalendarExtention from './calendarTools/CalendarExtention.js';
import { Walktour } from 'walktour';
import ButtonPrimary from './buttons/ButtonPrimary.js';
import ButtonBluePrimary from './buttons/ButtonBluePrimary.js';
import ButtonNext from './buttons/ButtonNext.js';
import ButtonSkip from './buttons/ButtonSkip.js';
import ButtonPrev from './buttons/ButtonPrev.js';

// TODO: use with all texts from backend!!!
function removeDiacritics(str) {
  return str.normalize("NFD").replace(/[\u0591-\u05BD\u05BF-\u05C2\u05C4-\u05C7]/g, "");
}

class Wall extends React.Component {
  constructor(props) {
    super(props);
    var loginOk = true;
    if (!localStorage.getItem("login_familyToken")) {
      loginOk = false;
    }
    this.state = {
      // new: show user menu modal
      showUserMenuModal: false,
      //
      isDemoEnabled: isDemoEnabled(),
      calendarDate: new Date(),
      locale: localStorage.getItem("locale") || "en",
      loginSaved: loginOk || isDemoEnabled(),
      family_token: localStorage.getItem("login_familyToken"),
      user_token: localStorage.getItem("login_userToken"),
      //task_date_type: TaskDateType.All,
      updateData: null,
      wrong_network: false,
      jsonData: null,
      dayIndicators: null,

      taskDateType: TaskDateType.WithDate,
      // take task dialog (is open toogle)
      takeIsOpen: false,
      // complete task dialog (is open toogle)
      taskCompleteIsOpen: false,
      // unassign task dialog (is open toogle)
      taskUnassignIsOpen: false,

      taskOnConfirmation: () => { },

      taskCompleteOnConfirmation: () => { },

      taskUnassignOnConfirmation: () => { }
    };
    console.log("open", this.state.takeIsOpen);
  }

  handleDateChange = (date) => {
    this.setState({ calendarDate: date });
  }

  getDaysInMonth(date) {
    return;
  }

  getSelectedCalendar() {
    return;
  }
  componentDidMount() {

    if ((!isDemoEnabled()) &&
      (localStorage.getItem("login_familyToken") == null || localStorage.getItem("login_userToken") == null)) {
      window.location = "/";
    }
    //
    /*
    family_token: localStorage.getItem("login_familyToken"),
      loginSaved: loginOk || isDemoEnabled(),
      user_token: localStorage.getItem("login_userToken"),
    */
    //
    const fetchData = async () => {
      try {
        var userToken = this.state.user_token;
        var familyToken = this.state.family_token;

        if (this.state.isDemoEnabled) {
          const d = new DemoAccount();
          var data = {
            "responseFamily": d.family,
            "responseProfile": d.supporter,
            "responseTasks": {
              "no_time": d.no_time,
              "events": d.events
            }
          };
        } else {
          const responseFamily = await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}/families/${familyToken}`);
          const responseProfile = await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}`);
          const responseTasks = await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}/families/${familyToken}/tasks`);
          var data = {
            "responseFamily": responseFamily.data,
            "responseProfile": responseProfile.data,
            "responseTasks": responseTasks.data
          };
        }
        var ind = generateMonthIndication(this.state.calendarDate.getMonth(), this.state.calendarDate.getFullYear(), data.responseTasks.events);
        this.setState({ jsonData: data, updateData: fetchData, dayIndicators: ind });

        window.show_notification_success = (msg, header) => NotificationManager.success(header, msg);

      } catch (error) {
        if (error.response && typeof error.response.status == 'number') {
          window.location = "/";
        }
        console.error('Error fetching data:', error);
        this.setState({ wrong_network: true });
      }
    };
    fetchData();
  }
  render() {
    //
    function groupAndCountTasks(tasks) {
      const groupedTasks = tasks.reduce((accumulator, currentValue) => {
        const date = new Date(currentValue.date);
        const month = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();
        const key = `${year}-${month}-${day}`;
        if (!accumulator[key]) {
          accumulator[key] = { month, monthDay: day, year, count: 0 };
        }
        accumulator[key].count++;
        return accumulator;
      }, {});
      const result = Object.values(groupedTasks);
      return result;
    }
    //
    if (this.state.wrong_network) {
      return <ErrorPage errType={LOAD_ERROR_TYPES.NETWORK_ERROR} />;
    }
    if (!this.state.loginSaved) {
      return <ErrorPage errType={LOAD_ERROR_TYPES.AUTH_TOKEN_NOT_FOUND} />;
    }
    if (this.state.jsonData == null) return Loader();
    var locale = this.state.locale;
    document.body.style.direction = locale === 'iw' ? 'rtl' : 'ltr';
    console.log("profile", this.state.jsonData);

    function areDatesEqual(date1, date2) {
      return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
      );
    }

    var taskCounter = this.state.jsonData.responseFamily.tasks_counter;
    var taskListWithDate = this.state.jsonData.responseTasks.events;

    for (var i = 0; i < taskListWithDate.length; i++) {
      var dateStart = new Date(taskListWithDate[i].date + "T" + taskListWithDate[i].start_at).toLocaleTimeString("ru");
      dateStart = dateStart.split(":")[0] + ":" + dateStart.split(":")[1];
      var dateEnd = new Date(taskListWithDate[i].date + "T" + taskListWithDate[i].end_at).toLocaleTimeString("ru");
      dateEnd = dateStart.split(":")[0] + ":" + dateStart.split(":")[1];
      var d = new Date(taskListWithDate[i].date + "T" + taskListWithDate[i].start_at);

      var d_with_time = new Date(taskListWithDate[i].date + "T" + taskListWithDate[i].start_at);

      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);
      taskListWithDate[i].d_with_time = d_with_time;
      taskListWithDate[i].js_date = d;
      taskListWithDate[i].time_start = dateStart;
      taskListWithDate[i].time_end = dateEnd;
      console.log("a task", taskListWithDate[i]);
    }
    taskListWithDate.sort((a, b) => a.d_with_time.getTime() - b.d_with_time.getTime());

    //
    var taskListDateless = this.state.jsonData.responseTasks.no_time;

    taskListDateless.sort((a, b) => a.id - b.id);
    //.status == "in_progress"
    taskListDateless.sort((a, b) => b.status == "in_progress" - a.status == "in_progress");
    taskListDateless.sort((a, b) => b.assigned_on_me - a.assigned_on_me);


    const monthsArray = [
      t("january"),
      t("february"),
      t("march"),
      t("april"),
      t("may"),
      t("june"),
      t("july"),
      t("august"),
      t("september"),
      t("october"),
      t("november"),
      t("december"),
    ];
    var dayList = [];
    console.log("family___", this.state.jsonData.responseFamily.coordinator.formatted_phone_number);
    for (var i = 0; i < this.state.dayIndicators.days_with_tasks.length; i++) {
      var d = this.state.dayIndicators.days_with_tasks[i];
      console.log("day", d);
      var tempDate = new Date(this.state.calendarDate);
      tempDate.setDate(d);
      console.log("temp_date", tempDate);
      var dayList_ = taskListWithDate.filter(e => areDatesEqual(e.js_date, tempDate) && e.status != "completed");
      console.log("dayList", dayList);



      dayList.push({
        el_id: `tasks_of_day_${d}`,
        currentDay: areDatesEqual(tempDate, new Date()),
        day: d, tasks: dayList_.map((itm, ind) => <TaskCardHome
          myTask={itm.assigned_on_me}
          coordinatorName={this.state.jsonData.responseFamily.coordinator.full_name}
          coordinatorNumber={this.state.jsonData.responseFamily.coordinator.formatted_phone_number}
          isEvent={true}
          isTaken={itm.status == "in_progress"}
          taskId={itm.id}
          key={itm.id}
          onTaken={this.state.updateData}
          taskName={itm.title}
          taskDescription={itm.description}
          taskTime={itm.time_start}
          timeRange={
            itm.time_start +
            "-" + itm.time_end
          }
          taskDate={
            <span> <span>{itm.date.split("-")[2]}</span> <span>{monthsArray[itm.date.split("-")[1] - 1]}</span></span>
          }
        />)
      });
    }

    if (
      this.state.calendarDate.getMonth() == new Date().getMonth() &&
      this.state.calendarDate.getFullYear() == new Date().getFullYear()
    ) {

      console.log("current month is selected");
      if (
        dayList.length == 0 ||
        (taskListWithDate.filter(e => areDatesEqual(new Date(e.date), new Date()) && e.status != "completed").length == 0)
      ) {
        var d = new Date().getDate();
        dayList.unshift({
          el_id: `tasks_of_day_${d}`,
          currentDay: true,
          day: d,
          tasks: [
            <div style={{ padding: 15, fontSize: 14 }}>
              {t('onlyDatelessStr')}
            </div>,
            taskListDateless.map((item, index) => (
              <TaskCardHome
                coordinatorName={this.state.jsonData.responseFamily.coordinator.full_name}
                coordinatorNumber={this.state.jsonData.responseFamily.coordinator.formatted_phone_number}
                isEvent={false}
                myTask={item.assigned_on_me}
                isTaken={item.status == "in_progress"}
                taskId={item.id}
                taskName={item.title}
                taskCards=''
                timeRange=''
                taskDate=''
                onTaken={this.state.updateData}
                taskDescription={item.description}
                taskTime='' />
            ))
          ],

        });
      } else {
        // find today's list and push dateless
        // ....
        var wasFound = false;

        for (var i = 0; i < dayList.length && !wasFound; i++) {
          var itm = dayList[i];
          if (itm.currentDay) {
            wasFound = true;
            itm.tasks.push(<div style={{ padding: 15, fontSize: 14 }}>
              {t('datelessStr')}
            </div>);
            itm.tasks = itm.tasks.concat(taskListDateless.map((item, index) => (
              <TaskCardHome
                myTask={item.assigned_on_me}
                coordinatorName={this.state.jsonData.responseFamily.coordinator.full_name}
                coordinatorNumber={this.state.jsonData.responseFamily.coordinator.formatted_phone_number}
                isEvent={false}
                isTaken={item.status == "in_progress"}
                taskId={item.id}
                taskName={item.title}
                taskCards=''
                timeRange=''
                taskDate=''
                onTaken={this.state.updateData}
                taskDescription={item.description}
                taskTime='' />
            )));
          }
        }
      }
    }

    const defaultTourButtons = (props) => <div style={{padding: 1, paddingTop: 15}}>
      <table style={{ width: '100%' }}><tbody><tr>
        <td style={{ width: 50 }}> <ButtonSkip onClick={() =>{ 
          localStorage.setItem('skipped', 'true');
          props.close();
        }} /> </td>
        <td> &nbsp; </td>
        <td style={{ width: 70 }}><ButtonPrev onClick={() => props.prev()} /></td>
        <td style={{ width: 70 }}><ButtonNext onClick={() => props.next()} /></td>
      </tr></tbody></table>
    </div>


    return (
      <TranslationContextProvider locale={this.state.locale}>
        <div style={{ overflowY: 'scroll', overflowX: 'hidden', height: ' calc(100%)' }}>

          {window.__install__pwa__ ? <div style={{ width: 140, margin: 'auto', padding: 10, height: 20 }}>
            <ButtonSecondary onClick={() => window.__install__pwa__()}>Install PWA</ButtonSecondary>
          </div> : <span></span>}
          <div>
            <table style={{ padding: 10, width: '98%', margin: '1%' }}>
              <tbody>
                <tr>




                  <td style={{ textAlign: 'center' }}>
                    <img src={logo} style={{ height: 57 }} alt="logo" />
                  </td>
                  <td style={{ width: 110 }}></td>
                  <td style={{ width: 70, padding: 0 }}> &nbsp; </td>
                  <td id='user_menu_component' style={{ textAlign: 'right', width: 40 }}>
                    <UserMenuButton onClick={() => this.setState({ showUserMenuModal: true })} />
                    <ProfileModal
                      setLocale={(value) => this.setState({ locale: value })}
                      userPhone={this.state.jsonData.responseProfile.formatted_phone_number}
                      fullName={this.state.jsonData.responseProfile.full_name}
                      isOpen={this.state.showUserMenuModal}
                      close={() => this.setState({ showUserMenuModal: false })} />
                  </td>

                </tr>
              </tbody>
            </table>

            <div id='user_profile_component' style={{ width: '290px', maxWidth: '95%', margin: 'auto' }}>
              &nbsp; &nbsp;
              <span style={{
                color: "#7D888D",
                fontSize: "15px",
                fontFamily: "Space Grotesk",
                fontWeight: 300,
                wordWrap: "break-word"
              }}> {t('helpingToFamily', '')}</span>
              &nbsp; &nbsp;
              <span>{this.state.jsonData.responseFamily.title}</span>
              &nbsp; &nbsp;
              <br />
              <br />
              <UserProfile
                family_name={this.state.jsonData.responseFamily.title}
                full_name={this.state.jsonData.responseProfile.full_name}
                locale={this.state.locale}
                part={taskCounter.in_progress + taskCounter.completed}
                total={taskCounter.initial + taskCounter.in_progress + taskCounter.completed} />
            </div>



            <div id='the_calendar_container'>
              <TheCalendar
                indicators={this.state.dayIndicators}
                locale={this.state.locale}
                date={this.state.calendarDate}
                setDate={(date) => {
                  var ind = generateMonthIndication(date.getMonth(), date.getFullYear(),
                    this.state.jsonData.responseTasks.events);
                  this.setState({ calendarDate: date, dayIndicators: ind });
                }}
              />
            </div>

            <div id='week_calendar_component'>
              <CalendarExtention
                counterResults={groupAndCountTasks(this.state.jsonData.responseTasks.events)}
                chooseDay={(d, m, y) => {
                  const newDate = new Date(y, m, d);
                  var ind = generateMonthIndication(newDate.getMonth(), newDate.getFullYear(),
                    this.state.jsonData.responseTasks.events);
                  console.log("date_choose", newDate);
                  this.setState({ calendarDate: newDate, dayIndicators: ind });
                }}
                monthDay={new Date().getDate()}
                selectedMonthDay={this.state.calendarDate.getDate()}
                year={this.state.calendarDate.getFullYear()}
                month={this.state.calendarDate.getMonth()}
              />
            </div>

            <ConfirmationModal
              onCancel={() => {
                this.setState({ takeIsOpen: false })
              }}
              onConfirm={this.state.taskOnConfirmation}
              isOpen={this.state.takeIsOpen == true} />
            <CompleteModal
              onCancel={() => {
                this.setState({ taskCompleteIsOpen: false })
              }}
              onConfirm={this.state.taskCompleteOnConfirmation}
              isOpen={this.state.taskCompleteIsOpen == true}
            />
            <UnassignModal
              onCancel={() => {
                this.setState({ taskUnassignIsOpen: false });
              }}
              onConfirm={this.state.taskUnassignOnConfirmation}
              isOpen={this.state.taskUnassignIsOpen == true}
            />

            <div id='take_tasks_component'>
              {dayList.map((item, index) => (<TaskDayList
                isSelectedDay={this.state.calendarDate.getDate() == item.day}
                id={item.el_id}
                key={index.toString()}
                dayWeekNumber={new Date(new Date(this.state.calendarDate).setDate(item.day)).getDay()}
                isCurrentDay={item.currentDay}
                dayNumber={item.day}
                taskCards={<div>{item.tasks}</div>} />
              ))}
            </div>
          </div>
          <br />

        </div>
        <div style={{ position: 'fixed', bottom: 0 }}>
          <NotificationContainer />
        </div>
        { ! localStorage.getItem('skipped') ?
        <Walktour
          steps={[
            // steps for page
            {
              selector: null, title: 'Welcome to Enola 👋', description: 'We want to introduce you app components',
              customFooterRenderer: (props) => <div><br /><ButtonPrimary onClick={props.next}>Next</ButtonPrimary></div>
            },
            /*{ selector: '#profile_name_component', title: 'Profile', description: 'Just info about current family and profile\nAlso with "My tasks button you can view taken tasks"' },*/


            {
              selector: '#progress_bar_component', title: 'Progress', description: 'Taken tasks progress',
              customFooterRenderer: defaultTourButtons
            },

            // profile_name_component

            // my_tasks_button_component

            // progress_bar_component
            {
              selector: '#the_calendar_container', title: 'Dropdown calendar', description: 'Select month, year, than click on days\nIn other side: "today" button, which will set the calendar to current day',
              customFooterRenderer: defaultTourButtons
            },
            {
              selector: '#week_calendar_component', title: '2 week calendar', description: 'Clickable days of current week and next one',
              customFooterRenderer: defaultTourButtons
            },

            {
              selector: '#take_tasks_component', title: 'Take tasks', description: 'For taking tasks press "View detils"',
              customFooterRenderer: defaultTourButtons
            },

            {
              selector: '#my_tasks_button_component', title: 'Taken tasks', description: 'View taken tasks by thhis button click',
              customFooterRenderer: defaultTourButtons
            },

            {
              selector: '#user_menu_component', title: 'Account menu', customDescriptionRenderer: () => <ul>
                <li>Change language 🌐 </li>
                <li>Current account info</li>
                <li>Switch accounts</li>
                <li>Logout</li>
                <li>Help</li>
              </ul>,
              customFooterRenderer: defaultTourButtons
            },
            {
              selector: null,
              title: 'Finish',
              description: "That's all we want to show at this page\n",
              customFooterRenderer: (props) => <ButtonPrimary onClick={() => {localStorage.setItem('skipped', 'true'); props.close()}}>Done</ButtonPrimary>
            }

            // steps at dropdown calendar... 
          ]}
        
        />:<span>&nbsp;</span>}
      </TranslationContextProvider>
    );
  }
}
export default Wall;

/*
<LogoutButton onClick={() => {
                      localStorage.removeItem("login_familyToken");
                      localStorage.removeItem("demoAccount");
                      window.location.reload();
                    }} />
*/