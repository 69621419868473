import './App.css';
import logo from './images/logo.png';
import LOAD_ERROR_TYPES from './enums/LoadErrorTypes';
import { TranslationContextProvider } from 'schummar-translate/react';
import t from './translations/translate.ts';
import ButtonPrimary from './buttons/ButtonPrimary.js';
import DemoAccount from './demo_mode/demo_account.js';
import partner_logo from './images/partner_logo.svg';

export default function ErrorPage({ errType }) {
    var errTitle = 'ERROR';
    var errDescription = 'Something hapend';
    var locale = localStorage.getItem("locale") || "en";
    switch (errType) {
        case LOAD_ERROR_TYPES.AUTH_TOKEN_NOT_FOUND:
            errTitle = t("errLoginTitle");
            errDescription = <div style={{ textAlign: 'left', fontSize: 15, textTransform: 'none', padding: 20 }}>
                <b>This page requires authentification.</b>
                <br /><br />

                <div style={{ fontSize: 16, color: 'black' }}>You have to:<br />
                    <ol>
                        <li>Contact family owner and recieve sign up link <br /> </li>
                        <li>Sign up (fill the form on sign up link click, you can view some tasks from family before filling the form)<br /></li>
                        <li> Click on login link (will be sent after sign up confirmation) in SMS message (if app is unstalled, you will be logged in the app)<br /></li>
                    </ol>
                </div>
                <br /><br />
                <i><b>If you want to try app without registration, you can use demo account (just click on button below)</b></i>
                <br /><br />

                <div style={{ width: 160, margin: 'auto' }}>
                    <ButtonPrimary onClick={() => { new DemoAccount(); window.location.href = "/"; window.location.reload(); }}>Demo account</ButtonPrimary>
                </div>

                


                
        <br />
        <table style={{ width: 140, margin: 'auto' }}>
          <tbody>
            <tr>
              <td><div style={{ height: 20 }}>Inspired by</div></td>
              <td><img style={{ height: 40, width: 40 }} src={partner_logo} /></td>
            </tr>
          </tbody>
        </table>


            </div>;

            break;
        case LOAD_ERROR_TYPES.AUTH_TOKEN_WRONG:
            errTitle = t("errAuthTitle");
            errDescription = t("errAuthDescription");
            break;
        case LOAD_ERROR_TYPES.PAGE_LOAD_ERROR:
            errTitle = t("errLoadTitle");
            errDescription = t("errLoadDescription");
            break;
        case LOAD_ERROR_TYPES.NETWORK_ERROR:
            errTitle = t("errNetworkTitle");
            errDescription = t("errNetworkDescription");
            break;
        case LOAD_ERROR_TYPES.SERVER_ERROR:
            errTitle = t("errServerTitle");
            errDescription = t("errServerDescription");
            break;
        case LOAD_ERROR_TYPES.WRONG_SIGN_UP_TOKEN:
            errTitle = "Wrong invite code";
            errDescription = "Please check your link";
            break;
    }
    return <div style={{ overflowY: 'scroll', overflowX: 'hidden', height: ' calc(100%)' }}>
        <TranslationContextProvider locale={locale}>
            <table className='AppBody' style={{ width: '100%' }}>
                <tbody style={{
                    textAlign: 'center',
                    height: 'calc(100%)',
                    width: '100%'
                }}>
                    <tr style={{ height: "15vh" }}>
                        <td>
                            <img style={{ height: "15vh" }} src={logo} alt="logo" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p style={{
                                color: '#313A56',
                                fontSize: '24px',
                                fontFamily: 'Space Grotesk',
                                fontWeight: 500,
                                textTransform: 'uppercase',
                                wordWrap: 'break-word'
                            }}>{errTitle}</p>
                            <p style={{
                                color: '#687498',
                                fontSize: '18px',
                                fontFamily: 'Space Grotesk',
                                fontWeight: 500,
                                textTransform: 'uppercase',
                                wordWrap: 'break-word'
                            }}>{errDescription}</p>
                        </td>
                    </tr>
                    <tr style={{ height: "5vh" }}>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </TranslationContextProvider>
    </div>
}
