import ButtonPrimary from "../buttons/ButtonPrimary";
import logo from '../images/logo.png';
import welcome_png from '../images/sign_up_welcome.png';
import partner_logo from '../images/partner_logo.svg';
import flower_in_hand from '../images/flower_in_hand.svg';
import { useState } from "react";
import Loader from "../Loader";
export default function SignUpWelcomePage({ familyName, letsGo }) {
    const [welcomeState, setWelcomeState] = useState(1);
    if (welcomeState == 1) {
        return <div style={{ overflowY: 'scroll', overflowX: 'hidden', height: ' calc(100%)', userSelect: 'none' }} onContextMenu={(e) => e.preventDefault()}>
            <div style={{ width: 180, margin: 'auto', marginTop: 10 }}>
                <img src={logo} style={{ width: 180 }} alt="logo" />
            </div>
            <div style={{ paddingTop: 20, width: 240, margin: 'auto', paddingBottom: 20 }}>
                <img style={{ width: 240 }} src={welcome_png} alt="hand" />
            </div>
            <div style={{ width: 280, margin: 'auto', color: 'black' }}>
                Thank you for wanting to support {familyName}<br />
                <br /><br />
                <b>Our goal in Enola is to help families in challenging times activate their support system in an effective way.</b>
                <br />
                <br />

                <table style={{ width: 140, margin: 'auto' }}>
                    <tbody>
                        <tr>
                            <td><div style={{ height: 20 }}>Inspired by</div></td>
                            <td><img style={{ height: 40, width: 40 }} src={partner_logo} /></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <ButtonPrimary onClick={()=> setWelcomeState(2)}>Continue</ButtonPrimary>
                <br /><br />
            </div>
        </div>
    } else if (welcomeState == 2) {
        return <div style={{ overflowY: 'scroll', overflowX: 'hidden', height: ' calc(100%)', userSelect: 'none' }} onContextMenu={(e) => e.preventDefault()}>
            <div style={{ width: 180, margin: 'auto', marginTop: 10 }}>
                <img src={logo} style={{ width: 180 }} alt="logo" />
            </div>
            <div style={{ paddingTop: 20, width: 240, margin: 'auto', paddingBottom: 20 }}>
                <img style={{ width: 240 }} src={flower_in_hand} alt="hand" />
            </div>
            <div style={{ width: 280, margin: 'auto', color: 'black' }}>
                <b>{familyName} mapped the tasks that can support her, now all you have to do is choose a task.
                <br /><br />
                Every small task is a huge help
                <br /><br /></b>
                <ButtonPrimary onClick={letsGo}>Let's start</ButtonPrimary>
                <br /><br />
            </div>
        </div>;
    } else return <Loader />;
}


/*

The family mapped the tasks that can support her, now all you have to do is choose a task.
            <br /><br />
            Every small task is a huge help

*/