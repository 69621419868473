import hearts from './images/hearts.png';
import hands from './images/bro.png'
import logo from './images/logo.png';
import loading from './images/loading.gif';
import t from './translations/translate.ts';

export default function Loader() {
    return (<div>

        <div style={{ width: 160, margin: 'auto' }}>
            <img style={{ zIndex: 0, height: 80, position: 'absolute', top: 5, }} src={logo} alt='Logo' />
        </div>
        <div style={{ zIndex: 8, position: 'absolute',
        width: '100%',
         background: 'transparent', top: '24vh',
          color: '#313E44', textAlign: 'center' }}>
            <p style={{ fontSize: '19px', fontWeight: 600, zIndex: 10, }}>
                {t('greatTo')}
            </p>
            <p style={{zIndex: 10,}}>
                {t('anyHelp')}
            </p>
            <p>
                <img style={{height: 25}} src={loading} alt='...'/>
            </p>

        </div>
        <img style={{
            zIndex: 0, width: '90%', margin: 25,
            position: 'absolute', top: 60
        }} src={hearts} alt='hearts' />
        <img style={{
            width: '100%', height: '51vh', zIndex: 0,
            position: 'absolute', bottom: 0
        }} src={hands} alt='hands' />
        
    </div>);
}
