import { useEffect, useState } from "react";
import ButtonPrimary from "./buttons/ButtonPrimary";
import logo from './images/logo.png';
import WhiteDropdown from "./buttons/WhiteDropdown";
import t from './translations/translate.ts';
import { TranslationContextProvider } from 'schummar-translate/react';


export default function DocViewer({ match }) {
    const docPath = match.params.docPath;
    const [disabled, setDisabled] = useState(true);
    const [locale, setLocale] = useState( localStorage.getItem("locale") == "en"?"en":"iw");
    useEffect(()=>{document.body.style.overflowY="hidden"});

    return <div className="AppBody" style={{direction: locale=="iw"?'rtl':'ltr'}}>
        <TranslationContextProvider locale={locale}>
        <div style={{height: 10}}></div>
        <table style={{height: '90vh', width: '100%'}}>
            <tbody>
                <tr style={{height: 50, textAlign: 'center'}}>
                <td style={{width: 50}}> &nbsp; </td>
                    <td>
                    <img src={logo} style={{height: 50}} alt="logo" />
                </td>
                <td style={{width: 50}}>
                <WhiteDropdown
                      txt={'🌐'+ (locale == "iw"? "He":( locale.split('')[0].toUpperCase() +  locale.split('')[1]))}
                      options={[
                        { label: 'He', value: 'iw' },
                        { label: 'En', value: 'en' },
                        { label: 'De', value: 'de' },
                        { label: 'Ru', value: 'ru' },
                      ]}
                      onSelect={(value) => {
                        setLocale(value);
                        localStorage.setItem("locale", value)
                      }}
                    />
                </td>
                </tr>
                <tr ><td colSpan={3}>
                    <div id="scroll_div"
                     style={{ direction:'ltr',
                     backgroundColor: 'white', padding: 10, marginLeft: 20, marginRight: 20, borderRadius: 15,
                     boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'
                     , height: '60vh', margin: 10, overflow: 'hidden' }}>
                        <iframe 
                        title="Doc"
                        onLoad={()=>{
                            const d = document.getElementById("doc_frame").contentWindow.document;
                            d.addEventListener('scroll', ()=>{
                                const {scrollTop, scrollHeight, clientHeight} = d.body;
                                setDisabled(Math.ceil( scrollTop ) / (scrollHeight - clientHeight) < 0.8)
                            }, false);
                        }}
                        
                        onScroll={()=>{
                            console.log("iframe scroll");
                        }} id="doc_frame" style={{width: '100%', height: '100%', border: 'none'}} 
                        src={`/docs/${locale}/${docPath}.html`}/>
                    </div>
                </td></tr>
                <tr style={{height: 70, }}><td colSpan={3}>
                    <span style={{color: '#FF4A32', padding: 21, display: disabled?'':'none'}}>{t('scrollToAgree')}</span>
                    <div style={{width: '200px', margin: 'auto', paddingTop: 10}}>
                    <ButtonPrimary onClick={()=>{if(!disabled){window.location.href='/'}}} disabled={disabled}>{t('ok_scroll')}</ButtonPrimary>
                    </div>
                </td></tr>
            </tbody>
        </table>
        </TranslationContextProvider>
    </div>
}