import React from "react";
import logo from './images/logo.png';
import t from './translations/translate.ts';
import { TranslationContextProvider } from 'schummar-translate/react';
import ProgressBar from "./ProgressBar.js";
import BackButton from "./buttons/BackButton.js";
import Loader from "./Loader";
import axios from "axios";
import TaskDateType from "./enums/TaskDateType";
import CompleteModal from "./CompleteModal";
import UnassignModal from "./UnassignModal";
import generateMonthIndication from "./Indication.ts";
import { SimpleGrid } from "@chakra-ui/react";
import ErrorPage from "./ErrorPage";
import LOAD_ERROR_TYPES from "./enums/LoadErrorTypes";
import TaskCardMyTasks from "./TaskCardMyTasks.js";
import DatelessSwitch from "./DatelessSwitch.js";
import WhiteDropdown from "./buttons/WhiteDropdown.js";
import ZeroTask from "./ZeroTasks.js";
import ButtonSecondary from "./buttons/ButtonSecondary.js";
import isDemoEnabled from "./demo_mode/is_demo_enabled.js";
import DemoAccount from "./demo_mode/demo_account.js";
import CONSTS from "./Constants.js";

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class MyTasks extends React.Component {
    constructor(props) {
        super(props);
        var loginOk = true;
        if (!localStorage.getItem("login_familyToken")) {
            loginOk = false;
        }
        this.state = {
            dayIndicators: null,
            calendarDate: new Date(),
            locale: localStorage.getItem("locale") || "en",
            loginSaved: loginOk || isDemoEnabled(),
            family_token: localStorage.getItem("login_familyToken"),
            user_token: localStorage.getItem("login_userToken"),
            taskDateType: TaskDateType.WithDate,
            updateData: null,
            jsonData: null,
            taskCompleteIsOpen: false,
            wrong_network: false,
            taskUnassignIsOpen: false,
            taskCompleteOnConfirmation: () => { },
            taskUnassignOnConfirmation: () => { },
            taskCompleteOnConfirmationTitle: "Title",
            taskH: { type: '-', id: 0 }
        };
    }
    componentDidMount() {
        window.location.hash = '';
        document.title = "My tasks  —  Enola";
        const fetchData = async () => {
            try {
                var responseTasks, responseFamily;
                if (isDemoEnabled()) {
                    const d = new DemoAccount();
                    responseTasks = {
                        "no_time": d.no_time,
                        "events": d.events
                    };
                    responseFamily = d.family;
                } else {
                    var userToken = this.state.user_token;
                    var familyToken = this.state.family_token;
                    responseTasks = (await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}/families/${familyToken}/tasks`)).data;
                    responseFamily = (await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}/families/${familyToken}`)).data;
                }
                window.show_notification_success = (msg, header)=>NotificationManager.success(header, msg);
                var coordinator = responseFamily.coordinator;
                console.log(responseTasks);
                var filteredArrayEvents = responseTasks.events.filter(function (obj) {
                    return obj.assigned_on_me === true && obj.status == "in_progress";
                });



                for (var i = 0; i < filteredArrayEvents.length; i++) {
                    var dateStart = new Date(filteredArrayEvents[i].date + "T" + filteredArrayEvents[i].start_at).toLocaleTimeString("ru");
                    dateStart = dateStart.split(":")[0] + ":" + dateStart.split(":")[1];
                    var dateEnd = new Date(filteredArrayEvents[i].date + "T" + filteredArrayEvents[i].end_at).toLocaleTimeString("ru");
                    dateEnd = dateStart.split(":")[0] + ":" + dateStart.split(":")[1];
                    var d = new Date(filteredArrayEvents[i].date + "T" + filteredArrayEvents[i].start_at);
                    d.setHours(0);
                    d.setMinutes(0);
                    d.setSeconds(0);
                    d.setMilliseconds(0);
                    filteredArrayEvents[i].js_date = d;
                    filteredArrayEvents[i].time_start = dateStart;
                    filteredArrayEvents[i].time_end = dateEnd;
                    console.log("a task", filteredArrayEvents[i]);
                }

                var filteredArrayNoTime = responseTasks.no_time.filter(function (obj) {
                    return obj.assigned_on_me === true && obj.status == "in_progress";
                });

                var data = {
                    "events": filteredArrayEvents,
                    "no_time": filteredArrayNoTime,
                    "taskCounter": responseFamily.tasks_counter,
                    "coordinator": coordinator
                };
                console.log("data", data);
                var ind = generateMonthIndication(this.state.calendarDate.getMonth() + 1, this.state.calendarDate.getFullYear(), data.events);
                this.setState({ jsonData: data, updateData: fetchData, dayIndicators: ind });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }
    render() {

        if (this.state.wrong_network) {
            return <ErrorPage errType={LOAD_ERROR_TYPES.NETWORK_ERROR} />;
        }

        if (!this.state.loginSaved) {
            return <ErrorPage errType={LOAD_ERROR_TYPES.AUTH_TOKEN_NOT_FOUND} />;
        }
        if (this.state.jsonData == null) return Loader();

        var taskListWithDate = this.state.jsonData.events;

        for (var i = 0; i < taskListWithDate.length; i++) {
            var d_with_time = new Date(taskListWithDate[i].date + "T" + taskListWithDate[i].start_at);
            taskListWithDate[i].d_with_time = d_with_time;
        }
        taskListWithDate.sort((a, b) => a.d_with_time.getTime() - b.d_with_time.getTime());

        var taskListDateless = this.state.jsonData.no_time;

        taskListDateless.sort((a, b) => a.id - b.id);
        taskListDateless.sort((a, b) => b.assigned_on_me - a.assigned_on_me);

        if (taskListDateless.length == 0 && taskListWithDate.length == 0) {
            //
            return <ZeroTask />;
            //
        }

        function areDatesEqual(date1, date2) {
            return (
                date1.getDate() === date2.getDate() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getFullYear() === date2.getFullYear()
            );
        }
        const monthsArray = [
            t("january"),
            t("february"),
            t("march"),
            t("april"),
            t("may"),
            t("june"),
            t("july"),
            t("august"),
            t("september"),
            t("october"),
            t("november"),
            t("december"),
        ];
        const searchParams = new URLSearchParams(window.location.search);

        setTimeout(() => {
            if (searchParams.get('fromPreview') && window._________PREVIEW_LODED != true) {

                var tId = parseInt(searchParams.get('fromPreview'));

                if (tId > 0) {
                    window.location.hash = `_with_date_${tId}`;
                    this.setState({ taskH: { type: 'date', id: tId } });
                } else if (tId < 0) {
                    tId = - tId;
                    window.location.hash = `_dateless_${tId}`;
                    this.setState({ taskH: { type: 'dateless', id: tId } });
                }


                console.log("PREVIEW HIGHLIGHT ENABLED", tId);
                window._________PREVIEW_LODED = true;
            }
        }, 400);
        return (
            <div style={{ overflowY: 'scroll', overflowX: 'hidden', height: ' calc(100%)' }}>
                <TranslationContextProvider locale={this.state.locale} >

                    {window.__install__pwa__ ? <div style={{ width: 140, margin: 'auto', padding: 10, height: 20 }}>
                        <ButtonSecondary onClick={() => window.__install__pwa__()}>Install PWA</ButtonSecondary>
                    </div> : <span></span>}

                    <div style={{ direction: this.state.locale == "iw" ? "rtl" : "ltr" }}>
                        <table style={{ width: '100%', padding: 20 }}><tbody>
                            <tr>
                                <td style={{ width: 25 }}>
                                    <BackButton onClick={() => document.location.href = '/tasks'} />
                                </td>
                                <td style={{padding: 0, width: 29}}> &nbsp; </td>
                                <td style={{ textAlign: 'center' }}>
                                    <img src={logo} style={{ height: 60, }} alt="logo" />
                                </td>
                                <td style={{ width: 25 }}>
                                    <WhiteDropdown
                                        txt={'🌐' + (this.state.locale == "iw" ? "He" : (this.state.locale.split('')[0].toUpperCase() + this.state.locale.split('')[1]))}
                                        options={[
                                            { label: 'He', value: 'iw' },
                                            { label: 'En', value: 'en' },
                                            { label: 'De', value: 'de' },
                                            { label: 'Ru', value: 'ru' },
                                        ]}
                                        onSelect={(value) => {
                                            this.setState({ locale: value });
                                            localStorage.setItem("locale", value)
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody></table>
                        <div style={{ textAlign: 'center', fontSize: '22px', fontWeight: 700, padding: 0 }}>{t('myTasks')}</div>

                        <div style={{
                            backgroundColor: '#F5F8FF', width: 290, borderRadius: 12,
                            margin: 'auto', marginTop: 10, height: 60
                        }}>
                            <ProgressBar
                                locale={this.state.locale}
                                part={this.state.jsonData.taskCounter.in_progress + this.state.jsonData.taskCounter.completed}
                                total={this.state.jsonData.taskCounter.initial + this.state.jsonData.taskCounter.in_progress + this.state.jsonData.taskCounter.completed}
                            />
                        </div>


                        <div >
                            {taskListWithDate.map((item, index) =>
                                <div key={"key_with_date_" + item.id} id={"_with_date_" + item.id}>
                                    <TaskCardMyTasks
                                        isEvent={true}
                                        highlighted={this.state.taskH.type == "date" && this.state.taskH.id == item.id}
                                        onComplete={this.state.updateData}
                                        taskId={item.id}
                                        taskName={item.title}
                                        taskDescription={item.description}
                                        taskTime={item.time_start}
                                        taskDate={
                                            <span> <span>{item.js_date.getDate()}</span> <span>{monthsArray[item.js_date.getMonth()]}</span></span>
                                        }
                                        coordinatorName={this.state.jsonData.coordinator.full_name}
                                        coordinatorNumber={this.state.jsonData.coordinator.formatted_phone_number}
                                    />
                                </div>
                            )}
                            {taskListDateless.map((item, index) => (
                                <div key={"key_dateless_" + item.id} id={"_dateless_" + item.id}>
                                    <TaskCardMyTasks
                                        onComplete={this.state.updateData}
                                        taskId={item.id}
                                        taskName={item.title}
                                        isEvent={false}
                                        highlighted={this.state.taskH.type == "dateless" && this.state.taskH.id == item.id}
                                        taskDescription={item.description}
                                        taskTime=""
                                        taskDate=""
                                        coordinatorName={this.state.jsonData.coordinator.full_name}
                                        coordinatorNumber={this.state.jsonData.coordinator.formatted_phone_number}
                                    />
                                </div>
                            ))}
                        </div>



                        <CompleteModal
                            onCancel={() => {
                                this.setState({ taskCompleteIsOpen: false })
                            }}
                            onConfirm={this.state.taskCompleteOnConfirmation}
                            isOpen={this.state.taskCompleteIsOpen == true}
                        />
                        <UnassignModal
                            onCancel={() => {
                                this.setState({ taskUnassignIsOpen: false });
                            }}
                            onConfirm={this.state.taskUnassignOnConfirmation}
                            isOpen={this.state.taskUnassignIsOpen == true}
                        />

                        <br />
                    </div>

                    <div style={{ position: 'fixed', bottom: 0 }}>
                        <NotificationContainer />
                    </div>

                </TranslationContextProvider>
            </div>
        );
    }
}
export default MyTasks
