import './buttons.css';
import './ButtonPrimary.css';
const ButtonPrimary = ({onClick, children, disabled = false}) => {
    return(
        <div style={{opacity: disabled? '0.4':'1.0'}} onClick={onClick} className='_custom_regular_button ButtonPrimary'>
            {children}
        </div>
    )
}
export default ButtonPrimary;
